@import '~@angular/material/theming';
@import "../../../theme/mixins/expansion-panel";

@mixin ssa-qip-page-theme($theme, $palette) {
  .qip-page {
    overflow-y: auto;
    height: calc(100vh - #{$header-height});
    padding: 25px 40px 30px 40px;
  }

  .qip-page__header {
    color: mat-color($palette, text);
    font-family: "Interstate", sans-serif;
    font-size: 22px !important;
    line-height: 30px !important;
    padding: 1rem 0;

    @include media-breakpoint-down(sm) {
      padding: 1rem 0;
    }
  }

  .qip-page__expansion-panel-container {
    @include ssa-expansion-panel($palette);

    margin-bottom: 3rem;

    .mat-expansion-panel-header {
      font-family: "Interstate", sans-serif;
      font-weight: normal;
      font-size: 14px;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }

    .mat-expansion-panel-content {
      font-family: "Interstate Light", sans-serif;
      font-size: 14px;
    }

    .mat-expansion-panel-header-description {
      width: 5rem;
    }
  }

  .qip-page__expansion-list-header-action-points {
    font-size: 12px;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
  }

  .qip-page__expansion-list-item {
    box-shadow: inset 0 -1px 0 0 rgba(111, 164, 215, .2);
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    &:last-of-type {
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  .qip_page__category-success-icon {
    color: #3fe02b;
    font-size: 20px;
  }

  .qip-page__expansion-list-item-question-container {
    display: flex;
  }

  .qip-page__expansion-list-item-question {
    margin: 0 !important;

    @include media-breakpoint-down(sm) {
      font-size: 12px;

    }
  }

  .qip-page__no-activities-container {
    margin-left: auto;
    background-color: #fff;
    border: 1px solid mat-color($palette, border);
    width: 45px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    .no-activities-label {
      font-family: "Interstate", sans-serif;
      font-size: 10px;
      color: mat-color($palette, deep-sea-blue);
      margin-right: 3px;
    }

    .no-activities-icon {
      color: #3fe02b;
      font-size: 14px;
      height: 16px;
      width: 16px;
    }
  }

  .qip-page__checkbox-activities-container {
    margin-left: auto;
    margin-right: 1rem;

    .mat-checkbox-background {
      background-color: #373D3F33;
      border: none;
    }

    .mat-checkbox-frame {
      border-width: 0 !important;
    };
  }

  .qip-page__back-to-home-button {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    width: 100%;
    height: 60px;
  }

}
