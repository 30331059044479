@import '~@angular/material/theming';

@mixin ssa-view-facility-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .view-facility-dialog {
    .mat-tab-header {
      margin-bottom: 1rem;

      @media print {
        display: none;
      }
    }

    .mat-tab-body-content {
      overflow-x: hidden;
    }

    @media print {
      @page {
        margin: 0;
      }

      body {
        margin: 1.6cm;
      }

      .button-bar {
        display: none;
      }
    }
  }

  .angular-editor-textarea {
    min-height: 10rem !important;
  }

  .view-facility-dialog__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: space-evenly;

      .mat-form-field {
        width: 45%;
      }
    }

    .mat-input-element {
      color: map_get($palette, text) !important;
    }
  }
}
