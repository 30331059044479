@import '~@angular/material/theming';

@mixin ssa-faq-page-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .faq-page {
    .question-container {
      margin-bottom: 20px;
      display: block;
      background-color: #fff;
      box-shadow: 1px 1px 1px 1px rgba(72, 72, 72, 0.1);
      border-radius: 2px;
      padding: 16px;

      .question {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .answer {
        font-size: 14px;
        font-weight: 500;
        font-family: "Interstate Light", sans-serif;
      }
    }
  }
}
