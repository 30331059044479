@import '~@angular/material/theming';

@mixin ssa-user-status-page-theme($theme, $palette) {
  .user-status-page {
    @extend .container;

    table {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .user-status-overview {
    overflow-x: auto;
    margin-top: 60px;

    .mat-header-cell {
      color: rgba(0, 0, 0, .7) !important;
      font-weight: bold !important;
      font-size: 14px !important;
      padding-right: 2rem;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .user-status-page__table-row {
    .mat-cell {
      color: rgba(0, 0, 0, .7) !important;
      font-weight: normal !important;
      font-size: 13px !important;
    }

    .mat-raised-button{
      background-color:#01548e;
      color: #ffffff;
    }

    .danger{
      background-color:#bd0404;
    }
  }

  .user-status-page__top-row {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
  }

  .top-row__search-bar {
    flex-grow: 1;

    mat-form-field {
      width: 90%;
    }
  }

  .user-status-page__table-row {
    cursor: pointer;

    &:hover {
      background-color: mat-color($palette, hover);
    }
  }
}
