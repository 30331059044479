@import '~@angular/material/theming';

@mixin ssa-checklist-tree-component-theme($theme, $palette) {
  .checklist__tree-header {
    color: mat-color($palette, text);
    cursor: pointer !important;

    .checklist__tree-label {
      font-size: 12px;
    }

    .mat-icon {
      font-size: 16px !important;
    }
  }

  .checklist-tree__checklist-container--assessor {
    .mat-checkbox {
      width: 100%;
    }

    .mat-checkbox-disabled .mat-checkbox-label {
      color: mat-color($palette, text);
    }

    .mat-checkbox-layout {
      width: 100%;

      .mat-checkbox-inner-container {
        margin-left: auto;
        margin-right: 22px;
        margin-top: 4px !important;
      }
    }
  }

  .checklist-tree__checklist-container--hidden {
    display: none !important;
  }

  .checklist-tree__checklist-container {
    padding-left: 1rem;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;

    @include media-breakpoint-down(sm) {
      margin: 10px 0;
    }

    .mat-checkbox-layout {
      white-space: normal;

      .mat-checkbox-label {
        font-family: "Interstate Light", sans-serif;
        font-size: 12px;
        padding-left: 0.5rem;
        padding-right: 1rem;
      }
    }

    .mat-checkbox-background {
      background-color: #373D3F33;
      border: none;
    }

    .mat-checkbox-frame {
      border-width: 0 !important;
    }
  }

  .checklist-tree__checklist-container-comment {
    margin-left: 1rem !important;
    font-family: "Interstate Light", sans-serif;
    font-size: 10px;
    color: mat-color($palette, text);
    margin-right: 3.5rem;
  }

  .checklist-tree__checklist-container-comment-prefix {
    font-weight: bolder;
    font-size: 11px;
  }
}
