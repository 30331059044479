@import '~@angular/material/theming';

@mixin ssa-app-bar-component-theme($theme, $palette) {
  .app-bar {
    display: block;
    position: relative;
    -ms-flex-order: -1;
    order: -1;
    width: 100%;
    z-index: 10;
    box-shadow: 0 1px 5px rgba(72, 72, 72, 0.3);

    .app-header-inner {
      color: map_get($palette, white);

      .mat-toolbar-row {
        min-height: 65px;
        padding: 0 $padding 0 8px;

        .mat-icon-button {
          margin-left: auto;
          width: 40px;

          .navigation-icon {
            line-height: 12px;
            font-size: 12px;
            width: 8px;
            height: 12px;
            color: mat-color($palette, soft-blue);
          }

          &:disabled {
            .navigation-icon {
              color: mat-color($palette, soft-blue, 0.6);
            }
          }
        }

        .menu-toggle-button {
          .mat-button-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .menu-label {
              line-height: 12px;
              font-size: 10px;
              text-transform: uppercase;
            }
          }
        }

        .header-label {
          flex: 1;
          font-weight: 300;
          font-family: "Interstate Light", sans-serif;
          text-align: center;
          margin-left: 40px; // for now to center align it because of menu button (quickest way to achieve this atm)

          &.with-back-btn {
            margin-left: 0;
          }
        }
      }
    }
  }
}



