:root {
  --theme-primary-50: #e0eaf1;
  --theme-primary-100: #b3ccdd;
  --theme-primary-200: #80aac7;
  --theme-primary-300: #4d87b0;
  --theme-primary-400: #266e9f;
  --theme-primary-500: #00548e;
  --theme-primary-600: #004d86;
  --theme-primary-700: #00437b;
  --theme-primary-800: #003a71;
  --theme-primary-900: #00295f;
  --theme-primary-A100: #90b6ff;
  --theme-primary-A200: #5d94ff;
  --theme-primary-A400: #2a73ff;
  --theme-primary-A700: #1062ff;
  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #ffffff;
  --theme-primary-contrast-400: #ffffff;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #ffffff;
  --theme-primary-contrast-A700: #ffffff;
  --theme-primary-deep-sea-blue: #00548e;
  --theme-primary-marine-blue: #013759;
  --theme-primary-soft-blue: #6fa4d7;
  --theme-primary-border: #d6e5f3;
  --theme-primary-hover: #d6e5f3;
  --theme-primary-background: #f4f6f8;
  --theme-primary-text: #2a4252;
  --theme-app-title: #fff;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.container, .facility-on-boarding-page, .assessor-home-page, .user-status-page, .facility-status-page, .assessors-page, .facilities-page, .translations-page {
  width: 100%;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container, .facility-on-boarding-page, .assessor-home-page, .user-status-page, .facility-status-page, .assessors-page, .facilities-page, .translations-page {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .facility-on-boarding-page, .assessor-home-page, .user-status-page, .facility-status-page, .assessors-page, .facilities-page, .translations-page {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .facility-on-boarding-page, .assessor-home-page, .user-status-page, .facility-status-page, .assessors-page, .facilities-page, .translations-page {
    max-width: 960px;
  }
}
@media (min-width: 1400px) {
  .container, .facility-on-boarding-page, .assessor-home-page, .user-status-page, .facility-status-page, .assessors-page, .facilities-page, .translations-page {
    max-width: 1263px;
  }
}

.container-fluid {
  width: 100%;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
:root {
  --theme-primary-50: #e0eaf1;
  --theme-primary-100: #b3ccdd;
  --theme-primary-200: #80aac7;
  --theme-primary-300: #4d87b0;
  --theme-primary-400: #266e9f;
  --theme-primary-500: #00548e;
  --theme-primary-600: #004d86;
  --theme-primary-700: #00437b;
  --theme-primary-800: #003a71;
  --theme-primary-900: #00295f;
  --theme-primary-A100: #90b6ff;
  --theme-primary-A200: #5d94ff;
  --theme-primary-A400: #2a73ff;
  --theme-primary-A700: #1062ff;
  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #ffffff;
  --theme-primary-contrast-400: #ffffff;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #ffffff;
  --theme-primary-contrast-A700: #ffffff;
  --theme-primary-deep-sea-blue: #00548e;
  --theme-primary-marine-blue: #013759;
  --theme-primary-soft-blue: #6fa4d7;
  --theme-primary-border: #d6e5f3;
  --theme-primary-hover: #d6e5f3;
  --theme-primary-background: #f4f6f8;
  --theme-primary-text: #2a4252;
  --theme-app-title: #fff;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.flex-row {
  display: flex !important;
  flex-direction: row;
}

.flex-col {
  display: flex !important;
  flex-direction: column;
}

.full-width {
  width: 100% !important;
  display: block;
}

.full-height {
  height: 100% !important;
  display: block;
}

.divider {
  border-left: 2px solid var(--ion-color-medium);
  margin: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.elevation {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.text-bold {
  font-weight: 500;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

.text-md-end {
  text-align: end !important;
}
@media (max-width: 991.98px) {
  .text-md-end {
    text-align: start !important;
  }
}

.text-start {
  text-align: start !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.no-wrap {
  white-space: nowrap;
}

.font-size-sm {
  font-size: 12px;
}

.font-size-md {
  font-size: 14px;
}

.font-size-lg {
  font-size: 16px;
}

.clickable {
  cursor: pointer;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.hidden {
  visibility: hidden !important;
  width: 0 !important;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.icon-btn {
  height: 60px;
  color: #fff;
}
.icon-btn .mat-button-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.icon-btn .mat-button-wrapper .icon-btn-label {
  flex: 1;
  white-space: normal;
  line-height: 20px;
}
.icon-btn .mat-button-wrapper .mat-icon {
  width: 20px;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
.color-primary {
  color: var(--theme-primary-500) !important;
}

.bgcolor-primary {
  background-color: var(--theme-primary-500) !important;
}

.color-accent {
  color: #1da356 !important;
}

.bgcolor-accent {
  background-color: #1da356 !important;
}

.color-app-title {
  color: var(--theme-app-title) !important;
}

.bgcolor-app-title {
  background-color: var(--theme-app-title) !important;
}

.color-white {
  color: #ffffff !important;
}

.bgcolor-white {
  background-color: #ffffff !important;
}

.color-accent-green {
  color: #76b354 !important;
}

.bgcolor-accent-green {
  background-color: #76b354 !important;
}

.color-neutral-blue {
  color: #8ac7e6 !important;
}

.bgcolor-neutral-blue {
  background-color: #8ac7e6 !important;
}

.color-positive-green {
  color: #70CB40 !important;
}

.bgcolor-positive-green {
  background-color: #70CB40 !important;
}

.color-warning-yellow {
  color: #F1CB2B !important;
}

.bgcolor-warning-yellow {
  background-color: #F1CB2B !important;
}

.color-warning-orange {
  color: #f1a82b !important;
}

.bgcolor-warning-orange {
  background-color: #f1a82b !important;
}

.color-negative-red {
  color: #ED2343 !important;
}

.bgcolor-negative-red {
  background-color: #ED2343 !important;
}

.color-text {
  color: var(--theme-primary-text) !important;
}

.bgcolor-text {
  background-color: var(--theme-primary-text) !important;
}

.color-pale-grey {
  color: #f0f5fb !important;
}

.bgcolor-pale-grey {
  background-color: #f0f5fb !important;
}

.color-deep-sea-blue {
  color: var(--theme-primary-deep-sea-blue) !important;
}

.bgcolor-deep-sea-blue {
  background-color: var(--theme-primary-deep-sea-blue) !important;
}

.color-marine-blue {
  color: var(--theme-primary-marine-blue) !important;
}

.bgcolor-marine-blue {
  background-color: var(--theme-primary-marine-blue) !important;
}

.color-soft-blue {
  color: var(--theme-primary-soft-blue) !important;
}

.bgcolor-soft-blue {
  background-color: var(--theme-primary-soft-blue) !important;
}

.color-link {
  color: #61bf89 !important;
}

.bgcolor-link {
  background-color: #61bf89 !important;
}

.color-border {
  color: var(--theme-primary-border) !important;
}

.bgcolor-border {
  background-color: var(--theme-primary-border) !important;
}

.color-hover {
  color: var(--theme-primary-hover) !important;
}

.bgcolor-hover {
  background-color: var(--theme-primary-hover) !important;
}

.color-light-blue-grey {
  color: #b1cbe5 !important;
}

.bgcolor-light-blue-grey {
  background-color: #b1cbe5 !important;
}

.color-background {
  color: var(--theme-primary-background) !important;
}

.bgcolor-background {
  background-color: var(--theme-primary-background) !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mh-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mv-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mh-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mv-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mh-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mv-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mh-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mv-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mh-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mv-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mh-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mv-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.ph-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.pv-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.ph-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.pv-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.ph-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.pv-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.ph-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.pv-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.ph-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.pv-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .mh-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mv-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .mh-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mv-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .mh-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mv-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .mh-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mv-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .mh-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mv-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .mh-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mv-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .ph-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pv-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ph-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .pv-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ph-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .pv-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .ph-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .pv-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ph-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .pv-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .ph-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .pv-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .mh-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mv-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .mh-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mv-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .mh-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mv-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .mh-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mv-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .mh-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mv-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .mh-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mv-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .ph-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pv-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .ph-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .pv-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .ph-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .pv-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .ph-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .pv-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .ph-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .pv-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .ph-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .pv-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .mh-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mv-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .mh-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mv-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .mh-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mv-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .mh-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mv-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .mh-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mv-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .mh-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mv-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .ph-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pv-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ph-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .pv-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ph-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .pv-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .ph-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .pv-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ph-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .pv-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .ph-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .pv-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .mh-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mv-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .mh-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .mv-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .mh-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mv-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .mh-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mv-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .mh-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mv-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .mh-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mv-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .ph-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pv-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ph-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .pv-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ph-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .pv-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .ph-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .pv-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ph-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .pv-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .ph-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .pv-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.td-1 {
  width: 8.3333333333%;
}

.td-2 {
  width: 16.6666666667%;
}

.td-3 {
  width: 25%;
}

.td-4 {
  width: 33.3333333333%;
}

.td-5 {
  width: 41.6666666667%;
}

.td-6 {
  width: 50%;
}

.td-7 {
  width: 58.3333333333%;
}

.td-8 {
  width: 66.6666666667%;
}

.td-9 {
  width: 75%;
}

.td-10 {
  width: 83.3333333333%;
}

.td-11 {
  width: 91.6666666667%;
}

.td-12 {
  width: 100%;
}

@media (min-width: 576px) {
  .td-sm-1 {
    width: 8.3333333333%;
  }

  .td-sm-2 {
    width: 16.6666666667%;
  }

  .td-sm-3 {
    width: 25%;
  }

  .td-sm-4 {
    width: 33.3333333333%;
  }

  .td-sm-5 {
    width: 41.6666666667%;
  }

  .td-sm-6 {
    width: 50%;
  }

  .td-sm-7 {
    width: 58.3333333333%;
  }

  .td-sm-8 {
    width: 66.6666666667%;
  }

  .td-sm-9 {
    width: 75%;
  }

  .td-sm-10 {
    width: 83.3333333333%;
  }

  .td-sm-11 {
    width: 91.6666666667%;
  }

  .td-sm-12 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .td-md-1 {
    width: 8.3333333333%;
  }

  .td-md-2 {
    width: 16.6666666667%;
  }

  .td-md-3 {
    width: 25%;
  }

  .td-md-4 {
    width: 33.3333333333%;
  }

  .td-md-5 {
    width: 41.6666666667%;
  }

  .td-md-6 {
    width: 50%;
  }

  .td-md-7 {
    width: 58.3333333333%;
  }

  .td-md-8 {
    width: 66.6666666667%;
  }

  .td-md-9 {
    width: 75%;
  }

  .td-md-10 {
    width: 83.3333333333%;
  }

  .td-md-11 {
    width: 91.6666666667%;
  }

  .td-md-12 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .td-lg-1 {
    width: 8.3333333333%;
  }

  .td-lg-2 {
    width: 16.6666666667%;
  }

  .td-lg-3 {
    width: 25%;
  }

  .td-lg-4 {
    width: 33.3333333333%;
  }

  .td-lg-5 {
    width: 41.6666666667%;
  }

  .td-lg-6 {
    width: 50%;
  }

  .td-lg-7 {
    width: 58.3333333333%;
  }

  .td-lg-8 {
    width: 66.6666666667%;
  }

  .td-lg-9 {
    width: 75%;
  }

  .td-lg-10 {
    width: 83.3333333333%;
  }

  .td-lg-11 {
    width: 91.6666666667%;
  }

  .td-lg-12 {
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .td-xl-1 {
    width: 8.3333333333%;
  }

  .td-xl-2 {
    width: 16.6666666667%;
  }

  .td-xl-3 {
    width: 25%;
  }

  .td-xl-4 {
    width: 33.3333333333%;
  }

  .td-xl-5 {
    width: 41.6666666667%;
  }

  .td-xl-6 {
    width: 50%;
  }

  .td-xl-7 {
    width: 58.3333333333%;
  }

  .td-xl-8 {
    width: 66.6666666667%;
  }

  .td-xl-9 {
    width: 75%;
  }

  .td-xl-10 {
    width: 83.3333333333%;
  }

  .td-xl-11 {
    width: 91.6666666667%;
  }

  .td-xl-12 {
    width: 100%;
  }
}
.card, .card-p5, .card-p4, .card-p3, .card-p2, .card-p1, .card-p0, .card-m5, .card-m4, .card-m3, .card-m2, .card-m1, .card-m0 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  min-height: 158px;
}
.card .card-header, .card-p5 .card-header, .card-p4 .card-header, .card-p3 .card-header, .card-p2 .card-header, .card-p1 .card-header, .card-p0 .card-header, .card-m5 .card-header, .card-m4 .card-header, .card-m3 .card-header, .card-m2 .card-header, .card-m1 .card-header, .card-m0 .card-header {
  width: 100%;
}
.card .card-header .ng-fa-icon, .card-p5 .card-header .ng-fa-icon, .card-p4 .card-header .ng-fa-icon, .card-p3 .card-header .ng-fa-icon, .card-p2 .card-header .ng-fa-icon, .card-p1 .card-header .ng-fa-icon, .card-p0 .card-header .ng-fa-icon, .card-m5 .card-header .ng-fa-icon, .card-m4 .card-header .ng-fa-icon, .card-m3 .card-header .ng-fa-icon, .card-m2 .card-header .ng-fa-icon, .card-m1 .card-header .ng-fa-icon, .card-m0 .card-header .ng-fa-icon {
  margin-right: 1rem;
}

.card-m0 {
  margin: 0 !important;
}

.card-m1 {
  margin: 0.25rem !important;
}

.card-m2 {
  margin: 0.5rem !important;
}

.card-m3 {
  margin: 1rem !important;
}

.card-m4 {
  margin: 1.5rem !important;
}

.card-m5 {
  margin: 3rem !important;
}

.card-p0 {
  padding: 0 !important;
}

.card-p1 {
  padding: 0.25rem !important;
}

.card-p2 {
  padding: 0.5rem !important;
}

.card-p3 {
  padding: 1rem !important;
}

.card-p4 {
  padding: 1.5rem !important;
}

.card-p5 {
  padding: 3rem !important;
}

* {
  box-sizing: border-box;
}
* a:hover,
* a:active,
* a:focus {
  text-decoration: none;
}
*:active,
* :focus {
  outline: none !important;
}

label {
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: Interstate, "Helvetica Neue", sans-serif;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  overflow: auto;
}

body > div {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

:root {
  --theme-primary-50: #e0eaf1;
  --theme-primary-100: #b3ccdd;
  --theme-primary-200: #80aac7;
  --theme-primary-300: #4d87b0;
  --theme-primary-400: #266e9f;
  --theme-primary-500: #00548e;
  --theme-primary-600: #004d86;
  --theme-primary-700: #00437b;
  --theme-primary-800: #003a71;
  --theme-primary-900: #00295f;
  --theme-primary-A100: #90b6ff;
  --theme-primary-A200: #5d94ff;
  --theme-primary-A400: #2a73ff;
  --theme-primary-A700: #1062ff;
  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #ffffff;
  --theme-primary-contrast-400: #ffffff;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #ffffff;
  --theme-primary-contrast-A700: #ffffff;
  --theme-primary-deep-sea-blue: #00548e;
  --theme-primary-marine-blue: #013759;
  --theme-primary-soft-blue: #6fa4d7;
  --theme-primary-border: #d6e5f3;
  --theme-primary-hover: #d6e5f3;
  --theme-primary-background: #f4f6f8;
  --theme-primary-text: #2a4252;
  --theme-app-title: #fff;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.form-group {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
}
.form-group .form-label {
  color: var(--theme-primary-text);
  width: 100%;
  display: block;
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}
.form-group .form-label.required:after {
  content: "*";
  color: #ed2343;
  font-size: 16px;
  padding-left: 3px;
}
.form-group select.form-input.ng-pristine {
  color: var(--theme-primary-deep-sea-blue);
}
.form-group select.form-input option {
  color: var(--theme-primary-text);
}
.form-group select.form-input option:active, .form-group select.form-input option:hover {
  background-color: var(--theme-primary-700);
}
.form-group select.form-input option:first-child {
  color: var(--theme-primary-deep-sea-blue);
}
.form-group .form-input {
  border: 2px solid var(--theme-primary-deep-sea-blue);
  padding: 10px;
  font-family: "Interstate", sans-serif;
  font-size: 16px;
  font-weight: 200;
  border-radius: 2px;
  background-color: white;
  color: var(--theme-primary-text);
}
.form-group .form-input::placeholder {
  color: var(--theme-primary-deep-sea-blue);
}
.form-group .form-input.ng-invalid.ng-dirty {
  border: 2px solid #ed2343;
}
.form-group .form-input:focus {
  border: 2px solid #70cb40;
}
.form-group .form-error {
  font-size: 14px;
  padding-top: 5px;
  color: #ed2343;
}
.form-group ol.form-options {
  list-style: none;
  counter-reset: listStyle;
  padding-inline-start: 0;
  padding-left: 0;
}
.form-group ol.form-options.horizontal {
  display: flex;
  justify-content: space-between;
}
.form-group ol.form-options.horizontal li {
  flex: 1;
  margin-right: 15px;
  float: right;
}
.form-group ol.form-options.horizontal li:last-child {
  margin-right: 0;
}
.form-group ol.form-options li {
  min-height: 60px;
  background-color: #fff;
  counter-increment: listStyle;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 15px;
}
.form-group ol.form-options li::before {
  content: counter(listStyle, upper-alpha);
  color: var(--theme-primary-soft-blue);
  border: 1px solid var(--theme-primary-border);
  padding: 7px 10px;
  background-color: var(--theme-primary-background);
  margin-right: 15px;
  font-size: 12px;
}
.form-group ol.form-options li.selected {
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
}
.form-group ol.form-options li.selected::before {
  background-color: var(--theme-primary-500);
  color: #fff;
}
.form-group ol.form-options li label {
  flex: 1;
  color: var(--theme-primary-text);
  font-family: "Interstate Light", sans-serif;
  padding: 19px 15px 20px 50px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: -50px;
}
.form-group ol.form-options li label:hover {
  cursor: pointer;
}
.form-group ol.form-options li:hover {
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
}

.form-button {
  width: 100%;
  height: 60px;
  text-align: center;
  font-family: "Interstate", sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.error-tooltip {
  border: 1px solid #ed2343;
  color: #ed2343;
  background: #fff;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
}
.error-tooltip:after, .error-tooltip:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.error-tooltip:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 7px;
  margin-left: -7px;
}
.error-tooltip:before {
  border-color: rgba(237, 35, 67, 0);
  border-top-color: #ed2343;
  border-width: 9px;
  margin-left: -9px;
}

agm-map {
  height: 350px !important;
  width: 500px;
}
@media (max-width: 600px) {
  agm-map {
    width: 350px;
  }
}
@media (max-width: 900px) {
  agm-map {
    width: 420px;
  }
}
@media (max-width: 410px) {
  agm-map {
    width: 270px;
    height: 270px !important;
  }
}

.active {
  background: #2972ffa1 !important;
}

.error {
  color: red;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: #0a2638;
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: var(--theme-primary-700);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: var(--theme-primary-700);
}
.mat-option.mat-active {
  background: var(--theme-primary-700);
  color: #0a2638;
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--theme-primary-500);
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1da356;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d71920;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #f4f6f8;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--theme-primary-500);
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1da356;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #d71920;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #f4f6f8;
  color: #0a2638;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 0px 0px rgba(0, 0, 0, 0.014), 0px 0px 0px 0px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.014), 0px 1px 3px 0px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.02), 0px 2px 2px 0px rgba(0, 0, 0, 0.014), 0px 1px 5px 0px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 4px 0px rgba(0, 0, 0, 0.014), 0px 1px 8px 0px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02), 0px 4px 5px 0px rgba(0, 0, 0, 0.014), 0px 1px 10px 0px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.02), 0px 5px 8px 0px rgba(0, 0, 0, 0.014), 0px 1px 14px 0px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.02), 0px 6px 10px 0px rgba(0, 0, 0, 0.014), 0px 1px 18px 0px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.02), 0px 7px 10px 1px rgba(0, 0, 0, 0.014), 0px 2px 16px 1px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.02), 0px 8px 10px 1px rgba(0, 0, 0, 0.014), 0px 3px 14px 2px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.02), 0px 9px 12px 1px rgba(0, 0, 0, 0.014), 0px 3px 16px 2px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.02), 0px 10px 14px 1px rgba(0, 0, 0, 0.014), 0px 4px 18px 3px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.02), 0px 11px 15px 1px rgba(0, 0, 0, 0.014), 0px 4px 20px 3px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.02), 0px 12px 17px 2px rgba(0, 0, 0, 0.014), 0px 5px 22px 4px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.02), 0px 13px 19px 2px rgba(0, 0, 0, 0.014), 0px 5px 24px 4px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.02), 0px 14px 21px 2px rgba(0, 0, 0, 0.014), 0px 5px 26px 4px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.02), 0px 15px 22px 2px rgba(0, 0, 0, 0.014), 0px 6px 28px 5px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.02), 0px 16px 24px 2px rgba(0, 0, 0, 0.014), 0px 6px 30px 5px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.02), 0px 17px 26px 2px rgba(0, 0, 0, 0.014), 0px 6px 32px 5px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.02), 0px 18px 28px 2px rgba(0, 0, 0, 0.014), 0px 7px 34px 6px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.02), 0px 19px 29px 2px rgba(0, 0, 0, 0.014), 0px 7px 36px 6px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.02), 0px 20px 31px 3px rgba(0, 0, 0, 0.014), 0px 8px 38px 7px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.02), 0px 21px 33px 3px rgba(0, 0, 0, 0.014), 0px 8px 40px 7px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.02), 0px 22px 35px 3px rgba(0, 0, 0, 0.014), 0px 8px 42px 7px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.02), 0px 23px 36px 3px rgba(0, 0, 0, 0.014), 0px 9px 44px 8px rgba(0, 0, 0, 0.012);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.02), 0px 24px 38px 3px rgba(0, 0, 0, 0.014), 0px 9px 46px 8px rgba(0, 0, 0, 0.012);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: #0a2638;
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02), 0px 4px 5px 0px rgba(0, 0, 0, 0.014), 0px 1px 10px 0px rgba(0, 0, 0, 0.012);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #0a2638;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: var(--theme-primary-contrast-500);
  background: var(--theme-primary-500);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #1da356;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #d71920;
}

.mat-badge-disabled .mat-badge-content {
  background: #b5b6b8;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.02), 0px 16px 24px 2px rgba(0, 0, 0, 0.014), 0px 6px 30px 5px rgba(0, 0, 0, 0.012);
  background: white;
  color: #0a2638;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: var(--theme-primary-500);
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #1da356;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #d71920;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: var(--theme-primary-500);
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #1da356;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #d71920;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: #0a2638;
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: var(--theme-primary-contrast-500);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: var(--theme-primary-500);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #1da356;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #d71920;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: var(--theme-primary-contrast-500);
  opacity: 0.1;
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 0px 0px rgba(0, 0, 0, 0.014), 0px 0px 0px 0px rgba(0, 0, 0, 0.012);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.02), 0px 2px 2px 0px rgba(0, 0, 0, 0.014), 0px 1px 5px 0px rgba(0, 0, 0, 0.012);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.02), 0px 8px 10px 1px rgba(0, 0, 0, 0.014), 0px 3px 14px 2px rgba(0, 0, 0, 0.012);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 0px 0px rgba(0, 0, 0, 0.014), 0px 0px 0px 0px rgba(0, 0, 0, 0.012);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.02), 0px 6px 10px 0px rgba(0, 0, 0, 0.014), 0px 1px 18px 0px rgba(0, 0, 0, 0.012);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.02), 0px 12px 17px 2px rgba(0, 0, 0, 0.014), 0px 5px 22px 4px rgba(0, 0, 0, 0.012);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 0px 0px rgba(0, 0, 0, 0.014), 0px 0px 0px 0px rgba(0, 0, 0, 0.012);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.02), 0px 2px 2px 0px rgba(0, 0, 0, 0.014), 0px 1px 5px 0px rgba(0, 0, 0, 0.012);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: #0a2638;
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #0a2638;
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: #0a2638;
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.014), 0px 1px 3px 0px rgba(0, 0, 0, 0.012);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 0px 0px rgba(0, 0, 0, 0.014), 0px 0px 0px 0px rgba(0, 0, 0, 0.012);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #f4f6f8;
}

.mat-checkbox-checkmark-path {
  stroke: #f4f6f8 !important;
}

.mat-checkbox-mixedmark {
  background-color: #f4f6f8;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--theme-primary-500);
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1da356;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #d71920;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: var(--theme-primary-500);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #1da356;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #d71920;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: #0a2638;
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #0a2638;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 4px 0px rgba(0, 0, 0, 0.014), 0px 1px 8px 0px rgba(0, 0, 0, 0.012);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--theme-primary-500);
  color: var(--theme-primary-contrast-500);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: var(--theme-primary-contrast-500);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: var(--theme-primary-contrast-500);
  opacity: 0.1;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #d71920;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #1da356;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: #0a2638;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #0a2638;
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: var(--theme-primary-500);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--theme-primary-500) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--theme-primary-500) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: var(--theme-primary-500);
  color: var(--theme-primary-contrast-500);
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--theme-primary-contrast-500);
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--theme-primary-500);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--theme-primary-500);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02), 0px 4px 5px 0px rgba(0, 0, 0, 0.014), 0px 1px 10px 0px rgba(0, 0, 0, 0.012);
  background-color: white;
  color: #0a2638;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(29, 163, 86, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(29, 163, 86, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(29, 163, 86, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1da356;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(29, 163, 86, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(29, 163, 86, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(29, 163, 86, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(215, 25, 32, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(215, 25, 32, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(215, 25, 32, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #d71920;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(215, 25, 32, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(215, 25, 32, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(215, 25, 32, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.02), 0px 24px 38px 3px rgba(0, 0, 0, 0.014), 0px 9px 46px 8px rgba(0, 0, 0, 0.012);
}

.mat-datepicker-toggle-active {
  color: var(--theme-primary-500);
}
.mat-datepicker-toggle-active.mat-accent {
  color: #1da356;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #d71920;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.02), 0px 24px 38px 3px rgba(0, 0, 0, 0.014), 0px 9px 46px 8px rgba(0, 0, 0, 0.012);
  background: white;
  color: #0a2638;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: #0a2638;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.02), 0px 2px 2px 0px rgba(0, 0, 0, 0.014), 0px 1px 5px 0px rgba(0, 0, 0, 0.012);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: var(--theme-primary-700);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: #0a2638;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--theme-primary-500);
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #1da356;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #d71920;
}

.mat-focused .mat-form-field-required-marker {
  color: #1da356;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--theme-primary-500);
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #1da356;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #d71920;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: var(--theme-primary-500);
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #1da356;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #d71920;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #d71920;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #d71920;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #d71920;
}

.mat-error {
  color: #d71920;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--theme-primary-500);
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #1da356;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #d71920;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #d71920;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: var(--theme-primary-500);
}
.mat-icon.mat-accent {
  color: #1da356;
}
.mat-icon.mat-warn {
  color: #d71920;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: var(--theme-primary-500);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #1da356;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #d71920;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #d71920;
}

.mat-list-base .mat-list-item {
  color: #0a2638;
}
.mat-list-base .mat-list-option {
  color: #0a2638;
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: var(--theme-primary-700);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: var(--theme-primary-700);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02), 0px 4px 5px 0px rgba(0, 0, 0, 0.014), 0px 1px 10px 0px rgba(0, 0, 0, 0.012);
}

.mat-menu-item {
  background: transparent;
  color: #0a2638;
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: var(--theme-primary-700);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: var(--theme-primary-100);
}

.mat-progress-bar-buffer {
  background-color: var(--theme-primary-100);
}

.mat-progress-bar-fill::after {
  background-color: var(--theme-primary-500);
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bee1d0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bee1d0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #1da356;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #edbfc2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #edbfc2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #d71920;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--theme-primary-500);
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #1da356;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #d71920;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--theme-primary-500);
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: var(--theme-primary-500);
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1da356;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #1da356;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d71920;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #d71920;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: #0a2638;
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02), 0px 4px 5px 0px rgba(0, 0, 0, 0.014), 0px 1px 10px 0px rgba(0, 0, 0, 0.012);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: var(--theme-primary-700);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--theme-primary-500);
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #1da356;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #d71920;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #d71920;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #f4f6f8;
  color: #0a2638;
}

.mat-drawer {
  background-color: white;
  color: #0a2638;
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.02), 0px 16px 24px 2px rgba(0, 0, 0, 0.014), 0px 6px 30px 5px rgba(0, 0, 0, 0.012);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1da356;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(29, 163, 86, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #1da356;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--theme-primary-500);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--theme-primary-500);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: var(--theme-primary-500);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #d71920;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(215, 25, 32, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #d71920;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.014), 0px 1px 3px 0px rgba(0, 0, 0, 0.012);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: var(--theme-primary-500);
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: var(--theme-primary-contrast-500);
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: var(--theme-primary-500);
  opacity: 0.2;
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #1da356;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(29, 163, 86, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #d71920;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(215, 25, 32, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: var(--theme-primary-700);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: var(--theme-primary-contrast-500);
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--theme-primary-500);
  color: var(--theme-primary-contrast-500);
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #1da356;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #d71920;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #d71920;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: #0a2638;
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #d71920;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: #0a2638;
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: #0a2638;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--theme-primary-100);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--theme-primary-500);
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--theme-primary-contrast-500);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 227, 204, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #1da356;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 186, 188, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #d71920;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--theme-primary-100);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: var(--theme-primary-500);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: var(--theme-primary-contrast-500);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--theme-primary-contrast-500);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--theme-primary-contrast-500);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--theme-primary-contrast-500);
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--theme-primary-contrast-500);
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 227, 204, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #1da356;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 186, 188, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #d71920;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: var(--theme-primary-900);
  color: #0a2638;
}
.mat-toolbar.mat-primary {
  background: var(--theme-primary-500);
  color: var(--theme-primary-contrast-500);
}
.mat-toolbar.mat-accent {
  background: #1da356;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #d71920;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: #0a2638;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.02), 0px 6px 10px 0px rgba(0, 0, 0, 0.014), 0px 1px 18px 0px rgba(0, 0, 0, 0.012);
}

.mat-simple-snackbar-action {
  color: #1da356;
}

a {
  color: var(--theme-primary-500);
  cursor: pointer;
}
a:hover {
  color: var(--theme-primary-500);
}

code {
  color: var(--theme-primary-500);
  background-color: #e0e0e0;
}

body {
  color: var(--theme-primary-text);
}

.mat-drawer-container {
  color: var(--theme-primary-text);
  background-color: var(--theme-primary-background);
}
.mat-drawer-container .mat-drawer {
  min-width: 270px;
}
.mat-drawer-container .mat-sidenav-content {
  overflow: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.mat-menu-panel {
  max-width: 350px !important;
}

select {
  border: 1px solid silver;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px;
  background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
  background-repeat: no-repeat;
  background-position: 97% 42%;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
}

button .mat-primary[disabled] {
  background-color: var(--theme-primary-500) !important;
  opacity: 0.6;
  color: #fff !important;
}

.mat-checkbox-background {
  background-color: #fff;
}

.mat-snack-bar-container.error {
  background-color: #ED2343;
}
.mat-snack-bar-container.error .mat-button {
  color: #fff;
}
.mat-snack-bar-container.warning {
  background-color: #F1CB2B;
}
.mat-snack-bar-container.warning .mat-button {
  color: #6c7d88;
}
.mat-snack-bar-container.success {
  background-color: #70CB40;
}
.mat-snack-bar-container.success .mat-button {
  color: #2a4252;
}
.mat-snack-bar-container.default {
  background-color: #0a2638;
}
.mat-snack-bar-container.default .mat-button {
  color: #fff;
}

.mat-select-panel {
  margin-top: 40px;
}

.unauthorized-page,
.page {
  left: 0;
  right: 0;
  top: 56px;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow-x: hidden;
  overflow-y: auto;
}
.unauthorized-page .page-content,
.page .page-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px;
  background-color: var(--theme-primary-background);
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  contain: size style;
  flex: 1 1 0;
  box-sizing: border-box;
}
@media (min-width: 576px) {
  .unauthorized-page,
.page {
    top: 64px;
  }
}

.unauthorized-page {
  top: 0;
}

h1 {
  color: var(--theme-primary-900);
  font-size: 20px;
  margin: 0;
}

h2 {
  color: var(--theme-primary-text);
  font-size: 16px;
  display: inline-block;
  width: auto;
  margin: 0;
  font-weight: normal;
}

h3 {
  color: var(--theme-primary-text);
  font-size: 14px;
  display: inline-block;
  width: auto;
  margin: 0;
  font-weight: normal;
}

.dialog {
  max-width: 80vw;
}

.custom-form-field {
  width: 100%;
}
.custom-form-field:not(:first-child) {
  margin-top: 16px;
}
.custom-form-field.mat-focused .mat-form-field-flex {
  border: 2px solid !important;
}
.custom-form-field .mat-icon {
  font-size: 100%;
  color: var(--theme-primary-500);
}
.custom-form-field .mat-icon.lock {
  opacity: 0.6;
}
.custom-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid var(--theme-primary-deep-sea-blue);
}
.custom-form-field.mat-form-field-appearance-fill .mat-form-field-flex .mat-select-value, .custom-form-field.mat-form-field-appearance-fill .mat-form-field-flex .mat-form-field-prefix {
  color: var(--theme-primary-500);
}
.custom-form-field.mat-form-field-appearance-fill .mat-form-field-flex .mat-form-field-prefix,
.custom-form-field.mat-form-field-appearance-fill .mat-form-field-flex .mat-form-field-suffix {
  align-self: center;
}
.custom-form-field.mat-form-field-appearance-fill .mat-form-field-flex .mat-form-field-prefix {
  margin-right: 8px;
}
.custom-form-field.mat-form-field-appearance-fill .mat-form-field-underline {
  display: none;
}
.custom-form-field.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-flex {
  border: 2px solid #ed2343;
}

.mat-option.mat-active {
  background-color: var(--theme-primary-hover) !important;
}

.mat-option:hover {
  background-color: var(--theme-primary-hover) !important;
}

.country-code-select {
  /* Country images copied from https://countrycode.org */
}
.country-code-select .flag {
  padding: 0 !important;
  margin: 0 5px 0 0;
  width: 16px;
  height: 11px;
  background: url(/assets/images/flagssprite.png) no-repeat;
}
.country-code-select .flag.Other {
  background: none;
}
.country-code-select .flag.Burundi {
  background-position: 0 -253px;
}
.country-code-select .flag.Congo-Brazzaville {
  background-position: 0 -440px;
}
.country-code-select .flag.Democratic-Republic-of-the-Congo {
  background-position: 0 -451px;
}
.country-code-select .flag.Ivory-Coast {
  background-position: 0 -462px;
}
.country-code-select .flag.Algeria {
  background-position: -16px -77px;
}
.country-code-select .flag.Egypt {
  background-position: -16px -110px;
}
.country-code-select .flag.Ethiopia {
  background-position: -16px -165px;
}
.country-code-select .flag.Ghana {
  background-position: -16px -319px;
}
.country-code-select .flag.Haiti {
  background-position: -16px -506px;
}
.country-code-select .flag.Indonesia {
  background-position: -16px -528px;
}
.country-code-select .flag.India {
  background-position: -16px -561px;
}
.country-code-select .flag.Jamaica {
  background-position: -32px -44px;
}
.country-code-select .flag.Kenya {
  background-position: -32px -77px;
}
.country-code-select .flag.Cambodia {
  background-position: -32px -99px;
}
.country-code-select .flag.Laos {
  background-position: -32px -198px;
}
.country-code-select .flag.Saint-Lucia {
  background-position: -32px -220px;
}
.country-code-select .flag.Liberia {
  background-position: -32px -242px;
}
.country-code-select .flag.Sri-Lanka {
  background-position: -32px -253px;
}
.country-code-select .flag.Myanmar {
  background-position: -32px -407px;
}
.country-code-select .flag.Malawi {
  background-position: -32px -517px;
}
.country-code-select .flag.Mozambique {
  background-position: -32px -550px;
}
.country-code-select .flag.Namibia {
  background-position: -32px -561px;
}
.country-code-select .flag.Nigeria {
  background-position: -48px -22px;
}
.country-code-select .flag.Netherlands {
  background-position: -48px -44px;
}
.country-code-select .flag.Peru {
  background-position: -48px -132px;
}
.country-code-select .flag.Papua-New-Guinea {
  background-position: -48px -154px;
}
.country-code-select .flag.Russia {
  background-position: -48px -319px;
}
.country-code-select .flag.Rwanda {
  background-position: -48px -330px;
}
.country-code-select .flag.Solomon-Islands {
  background-position: -48px -352px;
}
.country-code-select .flag.Sierra-Leone {
  background-position: -48px -462px;
}
.country-code-select .flag.Suriname {
  background-position: -48px -506px;
}
.country-code-select .flag.El-Salvador {
  background-position: -48px -528px;
}
.country-code-select .flag.Tunisia {
  background-position: -64px -77px;
}
.country-code-select .flag.Tuvalu {
  background-position: -64px -121px;
}
.country-code-select .flag.Tanzania {
  background-position: -64px -143px;
}
.country-code-select .flag.Uganda {
  background-position: -64px -165px;
}
.country-code-select .flag.Vietnam {
  background-position: -64px -275px;
}
.country-code-select .flag.Yemen {
  background-position: -64px -330px;
}

.invalid-message {
  color: #ed2343;
}

.admin-home-page {
  height: 100%;
  overflow-y: auto;
}
.admin-home-page .home-container {
  max-width: 280px;
  margin: 50px auto 0 auto;
  padding-bottom: 20px;
}
.admin-home-page .mat-raised-button {
  height: 55px;
  color: #fff;
  margin-top: 25px;
  line-height: 20px !important;
  white-space: pre-wrap !important;
}

.translations-page .mat-tree {
  padding: 16px;
}
.translations-page .mat-nested-tree-node li::marker {
  color: transparent;
}
.translations-page .translations-tree-invisible {
  display: none;
}
.translations-page .translations-tree ul,
.translations-page .translations-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.translations-page .translations-tree ul .mat-tree-node,
.translations-page .translations-tree li .mat-tree-node {
  width: 100%;
  cursor: pointer;
}
.translations-page .translations-tree ul .mat-tree-node:hover,
.translations-page .translations-tree li .mat-tree-node:hover {
  background-color: var(--theme-primary-hover);
}

.mat-tooltip {
  white-space: pre-line;
}

.toolbar {
  display: flex;
  align-items: baseline;
}
.toolbar .mat-form-field {
  margin: 0 1rem;
}
.toolbar .mat-form-field:first-of-type {
  margin-left: 0;
}
.toolbar .mat-form-field:last-of-type {
  margin-right: 0;
}

.facilities-page table {
  width: 100%;
  margin-bottom: 2rem;
}

.facilities-page__top-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.facilities-page__top-row .top-row__search-bar {
  flex-grow: 1;
}
.facilities-page__top-row .top-row__search-bar mat-form-field {
  max-width: 90%;
}
.facilities-page__top-row .top-row__download-button {
  height: 40px;
  margin-right: 2rem;
  align-self: flex-end;
  cursor: pointer;
}
.facilities-page__top-row .top-row__add-facility-button {
  height: 40px;
  line-height: 10px !important;
  margin: 0 4px;
}

.facilities-page__table-row {
  cursor: pointer;
}
.facilities-page__table-row:hover {
  background-color: var(--theme-primary-hover);
}

.assessors-page table {
  width: 100%;
  margin-bottom: 2rem;
}

.assessors-page__top-row {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}

.top-row__download-button {
  height: 40px;
  align-self: flex-end;
  margin-left: auto;
  cursor: pointer;
}

.top-row__add-facility-button {
  height: 40px;
  line-height: 10px !important;
  align-self: center;
  margin-left: 1rem;
}

.top-row__search-bar {
  flex-grow: 1;
}
.top-row__search-bar mat-form-field {
  width: 90%;
}

.assessors-page__table-row {
  cursor: pointer;
}
.assessors-page__table-row:hover {
  background-color: var(--theme-primary-hover);
}

.assessor-overview .mat-header-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.assessors-page__table-row .mat-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.add-users-dialog .header {
  font-weight: 700;
  font-size: 22px;
  color: var(--theme-primary-marine-blue);
  margin-bottom: 20px;
}
.add-users-dialog .button-bar {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
.add-users-dialog .button-bar .mat-button {
  flex: 1;
}

.edit-translation__input-area {
  padding: 0 !important;
  margin: 0 !important;
}

.add-facility-dialog {
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.add-facility-dialog .header {
  font-weight: 700;
  font-size: 22px;
  color: var(--theme-primary-marine-blue);
  margin-bottom: 20px;
}
.add-facility-dialog .form {
  overflow-x: auto;
  padding-right: 20px;
}
.add-facility-dialog .button-bar {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.add-facility-dialog .button-bar button {
  margin-right: 1rem;
}
.add-facility-dialog .button-bar button:last-of-type {
  margin-right: 0;
}
@media print {
  @page {
    .add-facility-dialog {
      margin: 0;
    }
  }
  .add-facility-dialog body {
    margin: 1.6cm;
  }
  .add-facility-dialog .button-bar,
.add-facility-dialog .facility-header {
    display: none;
  }
}

.edit-translation__input-area {
  padding: 0 !important;
  margin: 0 !important;
}

.view-facility-dialog .mat-tab-header {
  margin-bottom: 1rem;
}
@media print {
  .view-facility-dialog .mat-tab-header {
    display: none;
  }
}
.view-facility-dialog .mat-tab-body-content {
  overflow-x: hidden;
}
@media print {
  @page {
    .view-facility-dialog {
      margin: 0;
    }
  }
  .view-facility-dialog body {
    margin: 1.6cm;
  }
  .view-facility-dialog .button-bar {
    display: none;
  }
}

.angular-editor-textarea {
  min-height: 10rem !important;
}

.view-facility-dialog__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 576px) {
  .view-facility-dialog__content {
    justify-content: space-evenly;
  }
  .view-facility-dialog__content .mat-form-field {
    width: 45%;
  }
}
.view-facility-dialog__content .mat-input-element {
  color: var(--theme-primary-text) !important;
}

.facility-status-page table {
  width: 100%;
  margin-bottom: 2rem;
}

.facility-status-overview {
  overflow-x: auto;
  margin-top: 60px;
}
.facility-status-overview .mat-header-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: bold !important;
  font-size: 14px !important;
  padding-right: 2rem;
}
.facility-status-overview .mat-header-cell:last-of-type {
  padding-right: 0;
}

.facilities-status-page__table-row .mat-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: normal !important;
  font-size: 13px !important;
}
.facilities-status-page__table-row .mat-raised-button {
  background-color: #01548e;
  color: #ffffff;
}
.facilities-status-page__table-row .danger {
  background-color: #bd0404;
}

.facility-status-page__top-row {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}

.top-row__search-bar {
  flex-grow: 1;
}
.top-row__search-bar mat-form-field {
  width: 90%;
}

.facilities-status-page__table-row {
  cursor: pointer;
}
.facilities-status-page__table-row:hover {
  background-color: var(--theme-primary-hover);
}

.user-status-page table {
  width: 100%;
  margin-bottom: 2rem;
}

.user-status-overview {
  overflow-x: auto;
  margin-top: 60px;
}
.user-status-overview .mat-header-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: bold !important;
  font-size: 14px !important;
  padding-right: 2rem;
}
.user-status-overview .mat-header-cell:last-of-type {
  padding-right: 0;
}

.user-status-page__table-row .mat-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: normal !important;
  font-size: 13px !important;
}
.user-status-page__table-row .mat-raised-button {
  background-color: #01548e;
  color: #ffffff;
}
.user-status-page__table-row .danger {
  background-color: #bd0404;
}

.user-status-page__top-row {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}

.top-row__search-bar {
  flex-grow: 1;
}
.top-row__search-bar mat-form-field {
  width: 90%;
}

.user-status-page__table-row {
  cursor: pointer;
}
.user-status-page__table-row:hover {
  background-color: var(--theme-primary-hover);
}

.assessor-home-page {
  height: 100%;
  overflow-y: auto;
}
.assessor-home-page .assessor-home-button {
  margin-bottom: 1rem;
}
.assessor-home-page table {
  width: 100%;
  margin-bottom: 2rem;
}
.assessor-home-page .home-container {
  max-width: 280px;
  margin: 45px auto 0 auto;
  padding-bottom: 20px;
}
.assessor-home-page .icon-btn {
  line-height: 20px !important;
  white-space: pre-wrap !important;
}

.assessor-home-page__title {
  font-size: 32px !important;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.assessor-home-page__title-divider {
  margin-bottom: 2rem !important;
}

.view-administration-dialog .mat-tab-header {
  margin-bottom: 1rem;
}
.view-administration-dialog .mat-tab-body-content {
  overflow-x: hidden;
}

.angular-editor-textarea {
  min-height: 10rem !important;
}

.view-administration-dialog__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 576px) {
  .view-administration-dialog__content {
    justify-content: space-evenly;
  }
  .view-administration-dialog__content .mat-form-field {
    width: 45%;
  }
}
.view-administration-dialog__content .mat-input-element {
  color: var(--theme-primary-text) !important;
}

@media (min-width: 576px) {
  .email-container {
    width: 93% !important;
  }
}

.facility-on-boarding-page__button-bar {
  display: flex;
  justify-content: flex-end;
}

.qip-page {
  overflow-y: auto;
  height: calc(100vh - 64px);
  padding: 25px 40px 30px 40px;
}

.qip-page__header {
  color: var(--theme-primary-text);
  font-family: "Interstate", sans-serif;
  font-size: 22px !important;
  line-height: 30px !important;
  padding: 1rem 0;
}
@media (max-width: 767.98px) {
  .qip-page__header {
    padding: 1rem 0;
  }
}

.qip-page__expansion-panel-container {
  margin-bottom: 3rem;
}
.qip-page__expansion-panel-container .mat-expansion-panel-header:hover {
  background-color: var(--theme-primary-hover) !important;
}
.qip-page__expansion-panel-container .mat-expansion-panel-header-title {
  align-items: center;
  color: var(--theme-primary-text) !important;
  flex-grow: 0;
}
.qip-page__expansion-panel-container .mat-expansion-panel-header-description {
  color: var(--theme-primary-text) !important;
  flex-grow: 0;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .qip-page__expansion-panel-container .mat-accordion {
    display: block;
    width: 100vw;
    margin-left: -40px;
  }
}
.qip-page__expansion-panel-container .mat-expansion-indicator:after {
  color: var(--theme-primary-text) !important;
}
.qip-page__expansion-panel-container .mat-expansion-panel-header {
  font-family: "Interstate", sans-serif;
  font-weight: normal;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .qip-page__expansion-panel-container .mat-expansion-panel-header {
    font-size: 12px;
  }
}
.qip-page__expansion-panel-container .mat-expansion-panel-content {
  font-family: "Interstate Light", sans-serif;
  font-size: 14px;
}
.qip-page__expansion-panel-container .mat-expansion-panel-header-description {
  width: 5rem;
}

.qip-page__expansion-list-header-action-points {
  font-size: 12px;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .qip-page__expansion-list-header-action-points {
    font-size: 10px;
  }
}

.qip-page__expansion-list-item {
  box-shadow: inset 0 -1px 0 0 rgba(111, 164, 215, 0.2);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.qip-page__expansion-list-item:last-of-type {
  box-shadow: none;
  margin-bottom: 0;
}

.qip_page__category-success-icon {
  color: #3fe02b;
  font-size: 20px;
}

.qip-page__expansion-list-item-question-container {
  display: flex;
}

.qip-page__expansion-list-item-question {
  margin: 0 !important;
}
@media (max-width: 767.98px) {
  .qip-page__expansion-list-item-question {
    font-size: 12px;
  }
}

.qip-page__no-activities-container {
  margin-left: auto;
  background-color: #fff;
  border: 1px solid var(--theme-primary-border);
  width: 45px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.qip-page__no-activities-container .no-activities-label {
  font-family: "Interstate", sans-serif;
  font-size: 10px;
  color: var(--theme-primary-deep-sea-blue);
  margin-right: 3px;
}
.qip-page__no-activities-container .no-activities-icon {
  color: #3fe02b;
  font-size: 14px;
  height: 16px;
  width: 16px;
}

.qip-page__checkbox-activities-container {
  margin-left: auto;
  margin-right: 1rem;
}
.qip-page__checkbox-activities-container .mat-checkbox-background {
  background-color: #373D3F33;
  border: none;
}
.qip-page__checkbox-activities-container .mat-checkbox-frame {
  border-width: 0 !important;
}

.qip-page__back-to-home-button {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  width: 100%;
  height: 60px;
}

.checklist__tree-header {
  color: var(--theme-primary-text);
  cursor: pointer !important;
}
.checklist__tree-header .checklist__tree-label {
  font-size: 12px;
}
.checklist__tree-header .mat-icon {
  font-size: 16px !important;
}

.checklist-tree__checklist-container--assessor .mat-checkbox {
  width: 100%;
}
.checklist-tree__checklist-container--assessor .mat-checkbox-disabled .mat-checkbox-label {
  color: var(--theme-primary-text);
}
.checklist-tree__checklist-container--assessor .mat-checkbox-layout {
  width: 100%;
}
.checklist-tree__checklist-container--assessor .mat-checkbox-layout .mat-checkbox-inner-container {
  margin-left: auto;
  margin-right: 22px;
  margin-top: 4px !important;
}

.checklist-tree__checklist-container--hidden {
  display: none !important;
}

.checklist-tree__checklist-container {
  padding-left: 1rem;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
}
@media (max-width: 767.98px) {
  .checklist-tree__checklist-container {
    margin: 10px 0;
  }
}
.checklist-tree__checklist-container .mat-checkbox-layout {
  white-space: normal;
}
.checklist-tree__checklist-container .mat-checkbox-layout .mat-checkbox-label {
  font-family: "Interstate Light", sans-serif;
  font-size: 12px;
  padding-left: 0.5rem;
  padding-right: 1rem;
}
.checklist-tree__checklist-container .mat-checkbox-background {
  background-color: #373D3F33;
  border: none;
}
.checklist-tree__checklist-container .mat-checkbox-frame {
  border-width: 0 !important;
}

.checklist-tree__checklist-container-comment {
  margin-left: 1rem !important;
  font-family: "Interstate Light", sans-serif;
  font-size: 10px;
  color: var(--theme-primary-text);
  margin-right: 3.5rem;
}

.checklist-tree__checklist-container-comment-prefix {
  font-weight: bolder;
  font-size: 11px;
}

.score-page {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 64px);
  padding: 25px 20px 30px;
}
@media (max-width: 959px) {
  .score-page {
    height: calc(100vh - 56px);
  }
}
.score-page .score-page__score-display {
  text-align: center;
  padding: 0 20px;
}
.score-page .score-container__score-display-image {
  background-image: url("/assets/images/clouds.svg");
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.score-page .score-page__score-display-text-container {
  color: var(--theme-primary-text);
  line-height: 30px;
  font-weight: bold;
}
.score-page .score-page__score-display-text-container .score-text {
  font-size: 22px;
  display: block;
}
.score-page .score-page__score-display-text-container .score-text--green {
  color: #1da356;
}
.score-page .score-page__score-display-text-container .score-text--orange {
  color: #f1a82b;
}
.score-page .score-page__score-display-text-container .score-text--red {
  color: #ed2343;
}
.score-page .score-page__score-display-text-container .score-participation {
  font-size: 18px;
}
.score-page .score-page__score-display-score-container--assessor,
.score-page .score-page__score-display-score-container {
  border-radius: 30px;
  background-color: var(--theme-primary-500);
  margin-top: 2rem;
  color: #fff;
  padding: 2rem;
  min-width: 15rem;
}
.score-page .nhis-score-page__score-display-score-container--assessor-green,
.score-page .nhis-score-page__score-display-score-container-green {
  border-radius: 30px;
  background-color: #1da356;
  margin-top: 2rem;
  color: #fff;
  padding: 2rem;
  min-width: 15rem;
}
.score-page .nhis-score-page__score-display-score-container--assessor-green .score-label,
.score-page .nhis-score-page__score-display-score-container-green .score-label {
  font-size: 18px;
  margin-right: 5px;
  margin-bottom: 1rem;
}
.score-page .nhis-score-page__score-display-score-container--assessor-green .score-number,
.score-page .nhis-score-page__score-display-score-container-green .score-number {
  font-weight: 700;
  font-size: 22px;
}
.score-page .nhis-score-page__score-display-score-container--assessor-green .score-divider,
.score-page .nhis-score-page__score-display-score-container-green .score-divider {
  font-size: 18px;
  margin: 0 5px;
}
.score-page .nhis-score-page__score-display-score-container--assessor-red,
.score-page .nhis-score-page__score-display-score-container-red {
  border-radius: 30px;
  background-color: #ed2343;
  margin-top: 2rem;
  color: #fff;
  padding: 2rem;
  min-width: 15rem;
}
.score-page .nhis-score-page__score-display-score-container--assessor-red .score-label,
.score-page .nhis-score-page__score-display-score-container-red .score-label {
  font-size: 18px;
  margin-right: 5px;
  margin-bottom: 1rem;
}
.score-page .nhis-score-page__score-display-score-container--assessor-red .score-number,
.score-page .nhis-score-page__score-display-score-container-red .score-number {
  font-weight: 700;
  font-size: 22px;
}
.score-page .nhis-score-page__score-display-score-container--assessor-red .score-divider,
.score-page .nhis-score-page__score-display-score-container-red .score-divider {
  font-size: 18px;
  margin: 0 5px;
}
.score-page .score-page__score-display-score-container .score-label {
  font-size: 18px;
  margin-right: 5px;
  margin-bottom: 1rem;
}
.score-page .score-page__score-display-score-container .score-number {
  font-weight: 700;
  font-size: 22px;
}
.score-page .score-page__score-display-score-container .score-divider {
  font-size: 18px;
  margin: 0 5px;
}
.score-page .score-page__score-display-score-container--assessor {
  display: flex;
  justify-content: center;
}
.score-page .score-page__score-display-score-container--assessor .score-label {
  display: none;
}
.score-page .score-page__score-display-score-container--assessor .score-number,
.score-page .score-page__score-display-score-container--assessor .score-divider {
  font-size: 30px;
}
.score-page .score-page__score-display-score-container--assessor .score-number {
  font-weight: 700;
}
.score-page .score-page__score-display-score-container--assessor .score-divider {
  margin: 0 5px;
}
.score-page .score-page__score-display-explanation {
  font-weight: 200;
  font-size: 16px;
  color: var(--theme-primary-text);
  line-height: 24px;
  margin: 30px 0;
}
.score-page .icon-btn {
  width: 100%;
}

.score-page__assessor-comment {
  margin-bottom: 2rem;
}

.score-page__assessor-comment-title {
  margin: 0 !important;
}

.score-page__assessor-comment-container {
  border: none !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 14px !important;
  height: 5rem;
  padding: 10px;
}

.assessment-overview {
  width: 90%;
  margin: 4rem auto;
}
.assessment-overview table {
  width: 100%;
}
.assessment-overview .mat-header-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.assessment-overview__table-row .mat-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

.login-page {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
}
.login-page .safecare-logo {
  width: 100%;
  max-width: 280px;
  display: block;
  margin: 0 auto;
}
.login-page .safecare-logo-small {
  width: 100%;
  max-width: 140px;
  display: block;
  margin: 0 auto;
}
.login-page .login-container {
  margin: 90px auto 0 auto;
  max-width: 280px;
}
.login-page .login-container .mat-card {
  padding: 1.5rem;
}
.login-page .login-container .icon-btn {
  margin-top: 24px;
}
.login-page .login-container .sign-up-container {
  max-width: 375px;
  height: 60px;
  background-color: #f4f6f8;
  border: 2px solid #a2acb3;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.login-page .login-container .sign-up-container a {
  padding-left: 4px;
  font-weight: bold;
  text-decoration: none;
}
.login-page .login-container .forget-password-link {
  color: var(--theme-primary-text);
  text-decoration: none;
  display: block;
  font-size: 14px;
  text-align: right;
}
.login-page .login-container .password-field {
  margin-top: 0 !important;
}

.reset-password-page {
  height: 100%;
}
.reset-password-page .reset-container {
  width: 280px;
  margin: 75px auto 0 auto;
}
.reset-password-page .reset-container .login-form {
  display: flex;
  flex-direction: column;
}
.reset-password-page .reset-container .login-form .form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 20px;
}
.reset-password-page .reset-container .login-form .form-group input {
  border: none;
  flex: 1;
  margin-left: 10px;
  color: var(--theme-primary-500);
  font-size: 16px;
}
.reset-password-page .reset-container .login-form .form-group input::placeholder {
  color: var(--theme-primary-200);
  font-size: 16px;
}
.reset-password-page .reset-container .login-form .form-group .mat-icon {
  width: 10px;
  height: 10px;
  color: var(--theme-primary-500);
  font-size: 10px;
}
.reset-password-page .reset-container .login-form .icon-btn {
  margin-top: 54px;
}
.reset-password-page .reset-container .cancel-link {
  color: #61bf89;
  text-decoration: none;
  margin-top: 16px;
  display: block;
  text-align: center;
  font-size: 12px;
}

.update-password-page {
  height: 100%;
  overflow-y: auto;
}
.update-password-page .login-container {
  width: 280px;
  margin: 40px auto 0 auto;
}
.update-password-page .login-container .update-password-form {
  display: flex;
  flex-direction: column;
}
.update-password-page .login-container .update-password-form .form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 20px;
}
.update-password-page .login-container .update-password-form .form-group input {
  border: none;
  flex: 1;
  margin-left: 10px;
  color: var(--theme-primary-500);
  font-size: 16px;
}
.update-password-page .login-container .update-password-form .form-group input::placeholder {
  color: var(--theme-primary-200);
  font-size: 16px;
}
.update-password-page .login-container .update-password-form .form-group .mat-icon {
  width: 10px;
  height: 10px;
  color: var(--theme-primary-500);
  font-size: 10px;
}
.update-password-page .login-container .update-password-form .form-error {
  color: #ed2343;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
}
.update-password-page .login-container .update-password-form .mat-checkbox {
  padding-left: 20px;
  color: #fff;
  font-size: 12px;
}
.update-password-page .login-container .update-password-form .mat-checkbox a {
  color: #fff;
}
.update-password-page .login-container .update-password-form .icon-btn {
  margin-top: 54px;
}

.registration-info-page {
  height: 100vh;
  overflow-y: auto;
  color: var(--theme-primary-text);
}
.registration-info-page .info-content {
  padding: 50px;
}
.registration-info-page h3.subtitle {
  font-size: 24px;
  color: #fff;
}
.registration-info-page .mat-raised-button {
  height: 60px;
  margin-top: 20px;
}

.registration-page {
  height: 100%;
  overflow-y: auto;
}
.registration-page .page-header .app-subtitle {
  font-size: 28px;
}
.registration-page .registration-container {
  margin: 5rem auto 0;
  width: 100%;
  max-width: 320px;
  float: left;
  margin-left: 300px;
}
@media (max-width: 1150px) {
  .registration-page .registration-container {
    margin-left: 50px;
  }
}
.registration-page .registration-container .registration-form {
  display: block;
}
@media (max-width: 360px) {
  .registration-page .registration-container .registration-form .form-group {
    width: 75%;
  }
}
.registration-page .registration-container .registration-form .form-part {
  margin-bottom: 50px;
}
.registration-page .registration-container .registration-form .form-part h1.registration-header {
  font-size: 22px;
  color: var(--theme-primary-text);
  font-weight: bold;
}
.registration-page .registration-container .registration-form .form-part .maps {
  display: inline-flex;
}
@media (max-width: 600px) {
  .registration-page .registration-container .registration-form .form-part .maps {
    display: inline-block;
    margin-bottom: 10px;
  }
}
.registration-page .registration-container .registration-form .form-part .maps .mapsList {
  margin-right: 25px;
  max-height: 340px;
  overflow: auto;
  width: max-content;
}
@media (max-width: 600px) {
  .registration-page .registration-container .registration-form .form-part .maps .mapsList {
    margin-left: 0px;
    margin-bottom: 15px;
  }
}
@media (max-width: 360px) {
  .registration-page .registration-container .registration-form .form-part .maps .mapsList {
    margin-left: -15px;
    margin-bottom: 15px;
    width: 300px;
  }
}
.registration-page .registration-container .registration-form .form-part .maps .mapsList .mat-nav-list .mat-list-item {
  font-size: small;
  margin-right: 5px;
}
.registration-page .registration-container .registration-form .form-part .maps .mapsList .mat-list-item:hover {
  background: #eaeaf1;
}
.registration-page .registration-container .registration-form .form-part .phonenumberInput {
  padding: 10px;
  width: 153%;
  padding-left: 50px;
  border: 2px solid var(--theme-primary-deep-sea-blue);
}
@media (max-width: 360px) {
  .registration-page .registration-container .registration-form .form-part .phonenumberInput {
    width: 115%;
  }
}
.registration-page .registration-container .registration-form .form-part .form-label {
  color: var(--theme-primary-text);
}
.registration-page .registration-container .registration-form .form-part .form-label .mat-icon {
  background-color: #fff;
  border-radius: 50%;
  border: none;
}
.registration-page .registration-container .registration-form .form-part .form-error {
  background-color: #f3babc;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #d71920;
}
.registration-page .registration-container .registration-form .form-part .form-error .mat-icon {
  margin-right: 5px;
}
.registration-page .registration-container .registration-form .form-part .country-code-wrapper {
  display: flex;
}
.registration-page .registration-container .registration-form .form-part .country-code-wrapper .country-code-select {
  width: 165px;
  margin-right: 8px;
}
.registration-page .registration-container .registration-form .form-part .country-code-wrapper input.form-input {
  width: 100%;
}
.registration-page .registration-container .registration-form .icon-btn {
  margin-top: 8px;
}
.registration-page .registration-container .registration-form .password-input-wrapper {
  position: relative;
}
.registration-page .registration-container .registration-form .password-input-wrapper .form-input {
  width: 100%;
}
.registration-page .registration-container .registration-form .password-input-wrapper .mat-icon-button {
  position: absolute;
  right: 0;
  top: 0;
}
.registration-page .registration-container .cancel-link {
  color: var(--theme-primary-text);
  text-decoration: underline;
  margin: 20px 0;
  display: block;
  text-align: center;
  font-size: 12px;
}
.registration-page .registration-container .mat-checkbox {
  color: var(--theme-primary-text);
  font-size: 14px;
}
.registration-page .registration-container .mat-checkbox a {
  color: var(--theme-primary-text);
}
.registration-page .registration-container .mat-checkbox .mat-checkbox-frame {
  z-index: 1;
}
.registration-page .selected-facility {
  outline: none !important;
  border: 2px solid;
  border-image-slice: 1 !important;
  border-width: 3px;
  border-image-source: linear-gradient(to right, #0d6efd, #3d4095);
  animation: animate 2.5s linear infinite;
}
@keyframes animate {
  100% {
    filter: hue-rotate(-360deg);
  }
}

.onboarding-info-page {
  height: calc(100vh - 64px);
  overflow-y: auto;
  color: var(--theme-primary-text);
}
.onboarding-info-page .info-content {
  padding: 50px;
}
.onboarding-info-page h3.subtitle {
  font-size: 16px;
  color: var(--theme-primary-text);
}
.onboarding-info-page .mat-raised-button {
  height: 60px;
  margin-top: 32px;
}

.side-menu {
  height: 100%;
  position: relative;
}
.side-menu .mat-drawer {
  background-color: var(--theme-primary-500);
  color: #fff;
}
.side-menu .mat-drawer .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
}
.side-menu .mat-drawer .side-menu--list h3 {
  padding: 16px;
  text-transform: uppercase;
}
.side-menu .mat-drawer .side-menu--list h2.list--header {
  margin-top: 16px;
  padding-left: 16px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.side-menu .mat-drawer .side-menu--list .side-menu--item {
  box-sizing: border-box;
  color: #fff;
}
.side-menu .mat-drawer .side-menu--list .side-menu--item.active {
  background-color: var(--theme-primary-soft-blue);
  border-left: 3px solid var(--theme-primary-900);
}
.side-menu .mat-drawer .side-menu--list .side-menu--item.active .mat-icon {
  margin-right: 17px;
}
.side-menu .mat-drawer .side-menu--list .side-menu--item .mat-icon {
  margin-right: 20px;
}
.side-menu .mat-drawer .posters-container,
.side-menu .mat-drawer .logout-btn {
  margin: 10px;
}
.side-menu .mat-drawer .logout-btn {
  margin-top: auto;
  color: #fff;
  height: 60px;
  background-color: var(--theme-primary-marine-blue);
}

.side-menu-posters__title {
  margin-top: 16px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.side-menu-posters__poster {
  display: flex;
  text-decoration: none;
  color: #fff;
  height: 48px;
}
.side-menu-posters__poster .mat-icon {
  margin-right: 20px;
  margin-left: 6px;
}
.side-menu-posters__poster .menu-label {
  font-size: 16px;
}
.side-menu-posters__poster:hover {
  color: #fff;
}

.side-menu-additional-resources__title {
  margin-top: 16px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.side-menu-additional-resources__item {
  display: flex;
  text-decoration: none;
  color: #fff;
  height: 48px;
}
.side-menu-additional-resources__item .mat-icon {
  margin-right: 20px;
  margin-left: 6px;
}
.side-menu-additional-resources__item .menu-label {
  font-size: 16px;
}
.side-menu-additional-resources__item:hover {
  color: #fff;
}

.notification-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around;
  color: white;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.notification-container .mat-icon {
  margin-right: 10px;
}
.notification-container .message {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.loader-container {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.loader-container .loading-text {
  margin-top: 10px;
  text-align: center;
}

.app-bar {
  display: block;
  position: relative;
  -ms-flex-order: -1;
  order: -1;
  width: 100%;
  z-index: 10;
  box-shadow: 0 1px 5px rgba(72, 72, 72, 0.3);
}
.app-bar .app-header-inner {
  color: #ffffff;
}
.app-bar .app-header-inner .mat-toolbar-row {
  min-height: 65px;
  padding: 0 16px 0 8px;
}
.app-bar .app-header-inner .mat-toolbar-row .mat-icon-button {
  margin-left: auto;
  width: 40px;
}
.app-bar .app-header-inner .mat-toolbar-row .mat-icon-button .navigation-icon {
  line-height: 12px;
  font-size: 12px;
  width: 8px;
  height: 12px;
  color: var(--theme-primary-soft-blue);
}
.app-bar .app-header-inner .mat-toolbar-row .mat-icon-button:disabled .navigation-icon {
  color: var(--theme-primary-soft-blue);
}
.app-bar .app-header-inner .mat-toolbar-row .menu-toggle-button .mat-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-bar .app-header-inner .mat-toolbar-row .menu-toggle-button .mat-button-wrapper .menu-label {
  line-height: 12px;
  font-size: 10px;
  text-transform: uppercase;
}
.app-bar .app-header-inner .mat-toolbar-row .header-label {
  flex: 1;
  font-weight: 300;
  font-family: "Interstate Light", sans-serif;
  text-align: center;
  margin-left: 40px;
}
.app-bar .app-header-inner .mat-toolbar-row .header-label.with-back-btn {
  margin-left: 0;
}

.banner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 960px;
}

.banner--darker {
  filter: brightness(0.75);
}

.page-header {
  position: relative;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 25vh;
  width: 100%;
  padding: 30px;
  line-height: 35px;
}
.page-header .app-title {
  white-space: pre-wrap;
  font-size: 32px;
  color: var(--theme-app-title);
  text-align: center;
}
.page-header .app-subtitle {
  font-size: 20px;
}
.page-header .app-subtitle h2 {
  color: #fff !important;
}
.page-header [slot=logo] {
  margin-bottom: 16px;
}

.page-header-brighter {
  position: relative;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 17vh;
  width: 100%;
  padding: 30px;
  line-height: 35px;
}
.page-header-brighter .banner {
  background-size: contain;
  width: 95%;
  height: 86%;
  top: 5%;
  left: 2%;
  min-width: 0px;
}
.page-header-brighter .app-title {
  white-space: pre-wrap;
  font-size: 32px;
  color: #005022;
  position: relative;
  top: 60px;
  transform: translateY(110%);
}
@media (min-width: 700px) {
  .page-header-brighter .app-title {
    transform: translateY(80%);
    margin-top: 70px;
  }
}
@media (max-width: 700px) {
  .page-header-brighter .app-title {
    top: 60px;
  }
}
@media (max-width: 350px) {
  .page-header-brighter .app-title {
    top: 15px;
  }
}
.page-header-brighter .app-subtitle {
  font-size: 20px;
  color: #005022;
  position: relative;
  top: 80px;
}
@media (max-width: 700px) {
  .page-header-brighter .app-subtitle {
    top: 110px;
  }
}
.page-header-brighter .app-subtitle h2 {
  color: #fff !important;
}

.password-validator {
  display: flex;
  flex-direction: column;
}
.password-validator .validation {
  display: flex;
  align-items: flex-start;
  color: var(--theme-primary-text);
  padding: 3px 0;
}
.password-validator .validation .mat-icon {
  margin-right: 5px;
}
.password-validator .validation .mat-icon.success {
  color: #1a9b4f;
}

.onboarding-info-page {
  overflow-y: auto;
  color: var(--theme-primary-text);
}
.onboarding-info-page .info-content {
  padding: 50px;
}
.onboarding-info-page h3.subtitle {
  font-size: 16px;
  color: var(--theme-primary-text);
}
.onboarding-info-page .mat-raised-button {
  height: 60px;
  margin-top: 32px;
}

.assessment-subtitle {
  font-size: 24px !important;
  width: 100% !important;
  margin-bottom: 2rem !important;
  text-align: center;
}

.navigation-bar {
  height: 60px;
  line-height: 60px;
  min-height: 60px;
  margin: 10px 0 30px 0;
  display: flex;
  justify-content: center;
}
.navigation-bar .submit-button {
  width: 180px;
  height: 60px;
  line-height: 20px;
  min-height: 60px;
  white-space: pre-wrap !important;
}
.navigation-bar .submit-button:first-child {
  margin-right: 10px;
}

.export-button-bar {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}
.export-button-bar .export-button {
  background-color: #f0f5fb;
  border: 1px solid #b1cbe5;
  border-radius: 4px;
  color: var(--theme-primary-text);
  font-family: "Interstate Light", sans-serif;
  font-size: 12px;
  width: 180px;
  white-space: pre-wrap !important;
  line-height: 17px;
  padding: 10px;
}
.export-button-bar .export-button:first-child {
  margin-right: 10px;
}
.export-button-bar .export-button:nth-child(2) {
  margin-right: 10px;
}
.export-button-bar .export-button:nth-child(3) {
  margin-right: 10px;
}
.export-button-bar .export-button .mat-icon {
  color: #b1cbe5;
  margin-right: 10px;
  font-size: 8px;
  width: 8px;
  height: 11px;
}

.question-list-pager__question-page,
.question-list-pager__question-list-item {
  margin-bottom: 1rem;
}

.question-list-pager__question-list-item {
  box-shadow: inset 0 -1px 0 0 rgba(111, 164, 215, 0.2);
  margin-bottom: 2rem;
}
.question-list-pager__question-list-item:last-of-type {
  box-shadow: none;
  margin-bottom: 0;
}

.question-list-pager__question-page-category {
  display: flex;
  align-items: center;
  width: 100vw;
  background-color: white;
  margin-left: -40px;
  padding: 40px;
  height: 1.5rem;
  position: absolute;
  top: 0;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.question-list-pager__question-page-category h2, .question-list-pager__question-page-category p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.question-list-pager__question-page-category--title {
  color: var(--theme-primary-text) !important;
}

.question-list-pager__question-page-category--overview-button {
  margin-left: auto !important;
  margin-right: 1rem !important;
  opacity: 0;
  cursor: auto !important;
}

.question-list-pager__question-page-category--overview-button,
.question-list-pager__question-page-category--count,
.question-list-pager__question-page-category--category-button {
  color: var(--theme-primary-soft-blue);
}

.question-list-pager__question-page-category--count {
  background-color: var(--theme-primary-background);
  padding: 5px 10px;
  white-space: nowrap;
}

.question-list-pager__question-page-content {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.question-list-pager__question-page-overview .mat-expansion-panel-header:hover {
  background-color: var(--theme-primary-hover) !important;
}
.question-list-pager__question-page-overview .mat-expansion-panel-header-title {
  align-items: center;
  color: var(--theme-primary-text) !important;
  flex-grow: 0;
}
.question-list-pager__question-page-overview .mat-expansion-panel-header-description {
  color: var(--theme-primary-text) !important;
  flex-grow: 0;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .question-list-pager__question-page-overview .mat-accordion {
    display: block;
    width: 100vw;
    margin-left: -40px;
  }
}
.question-list-pager__question-page-overview .mat-expansion-indicator:after {
  color: var(--theme-primary-text) !important;
}

.question_list-pager__error-container,
.question_list-pager__category-container {
  height: 20px;
  margin-bottom: 2rem;
}

.question-list-pager__category-container,
.question-list-pager__submit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.question-list-pager__submit-container {
  margin-bottom: 2rem;
}

.question-list-pager__button {
  font-size: 14px !important;
  max-width: 280px;
  white-space: nowrap !important;
}

.question-list-pager__success-icon {
  color: #3fe02b;
  font-size: 20px;
  vertical-align: middle;
}

.assessment-subtitle--optional {
  display: none;
}

.question-list-pager__question-page-expansion-panel-description {
  width: 5rem;
}

.form-label--parent {
  margin-bottom: 0 !important;
}

.form-label--child {
  font-size: 16px !important;
}

.question-viewer__title-container--parent {
  font-weight: bold;
}

.question-viewer__title-container {
  display: flex;
  align-items: center;
}

.question-viewer__title-info-button {
  margin-left: auto;
}

.question-viewer__note-title {
  margin: 0 !important;
}

.question-viewer__note-container {
  border: none !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 14px !important;
}

.option-image-container {
  display: flex;
  flex-direction: column;
}
.option-image-container .option-image {
  max-width: 100%;
}

dl.guidelines-popover-list {
  margin: 0;
  width: 100%;
  color: var(--theme-primary-text);
}
dl.guidelines-popover-list dt {
  font-weight: bold;
}
dl.guidelines-popover-list dd {
  margin-inline-start: 0;
  margin-left: 0;
}
dl.guidelines-popover-list dd:not(:last-child) {
  margin-bottom: 10px;
}

.mat-icon-button.guidelines-popover-btn {
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.guidelines-popover-title {
  margin-bottom: 1rem;
  box-shadow: inset 0 -1px 0 0 rgba(111, 164, 215, 0.2);
}

.ssa-popover .mat-menu-item {
  cursor: auto;
  height: auto;
  white-space: normal;
  line-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
}
.ssa-popover .mat-menu-item.cdk-program-focused:not([disabled]), .ssa-popover .mat-menu-item:hover:not([disabled]) {
  background: inherit;
}
.ssa-popover .mat-menu-content {
  padding: 0;
}

.trigger {
  display: inline-block;
}

.facility-overview {
  overflow-x: auto;
  margin-top: 60px;
}
.facility-overview .mat-header-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: bold !important;
  font-size: 14px !important;
  padding-right: 2rem;
}
.facility-overview .mat-header-cell:last-of-type {
  padding-right: 0;
}

.facilities-page__table-row .mat-cell {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: normal !important;
  font-size: 13px !important;
}

h2 {
  color: #1a9b4f;
}

.home-page {
  height: 100%;
  overflow-y: auto;
}
.home-page .home-container {
  max-width: 280px;
  margin: 55px auto 0 auto;
  padding-bottom: 20px;
}
.home-page .icon-btn {
  margin-top: 25px;
  line-height: 20px !important;
  white-space: pre-wrap !important;
}

.faq-page .question-container {
  margin-bottom: 20px;
  display: block;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(72, 72, 72, 0.1);
  border-radius: 2px;
  padding: 16px;
}
.faq-page .question-container .question {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.faq-page .question-container .answer {
  font-size: 14px;
  font-weight: 500;
  font-family: "Interstate Light", sans-serif;
}

.not-found-page {
  overflow-y: auto;
  height: calc(100vh - 64px);
  padding: 25px 40px 30px 40px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Interstate, sans-serif;;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Interstate, sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Interstate, sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Interstate, sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Interstate, sans-serif;;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Interstate, sans-serif;;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Interstate, sans-serif;;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Interstate, sans-serif;;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Interstate, sans-serif;;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Interstate, sans-serif;;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Interstate, sans-serif;;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Interstate, sans-serif;;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Interstate, sans-serif;;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Interstate, sans-serif;;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Interstate, sans-serif;;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Interstate, sans-serif;;
  font-size: 16px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Interstate, sans-serif;;
}

.mat-card {
  font-family: Interstate, sans-serif;;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Interstate, sans-serif;;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Interstate, sans-serif;;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Interstate, sans-serif;;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 16px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Interstate, sans-serif;;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Interstate, sans-serif;;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Interstate, sans-serif;;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Interstate, sans-serif;;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Interstate, sans-serif;;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Interstate, sans-serif;;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Interstate, sans-serif;;
}

.mat-select {
  font-family: Interstate, sans-serif;;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Interstate, sans-serif;;
}

.mat-slider-thumb-label-text {
  font-family: Interstate, sans-serif;;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Interstate, sans-serif;;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Interstate, sans-serif;;
}

.mat-tab-label, .mat-tab-link {
  font-family: Interstate, sans-serif;;
  font-size: 16px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Interstate, sans-serif;;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Interstate, sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Interstate, sans-serif;;
}

.mat-list-option {
  font-family: Interstate, sans-serif;;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Interstate, sans-serif;;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Interstate, sans-serif;;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Interstate, sans-serif;;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Interstate, sans-serif;;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Interstate, sans-serif;;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Interstate, sans-serif;;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}