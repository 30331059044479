@import '~@angular/material/theming';

@mixin ssa-assessor-home-page-theme($theme, $palette) {
  .assessor-home-page {
    @extend .container;

    .assessor-home-button {
      margin-bottom: 1rem;
    }

    table {
      width: 100%;
      margin-bottom: 2rem;
    }

    height: 100%;
    overflow-y: auto;

    .home-container {
      max-width: 280px;
      margin: 45px auto 0 auto;
      padding-bottom: 20px;
    }

    .icon-btn {
      line-height: 20px !important;
      white-space: pre-wrap !important;
    }
  }

  .assessor-home-page__title {
    font-size: 32px !important;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }

  .assessor-home-page__title-divider {
    margin-bottom: 2rem !important;
  }
}
