@import '~@angular/material/theming';

@mixin ssa-side-menu-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .side-menu {
    height: 100%;
    position: relative;

    .mat-drawer {
      background-color: mat-color($palette, primary);
      color: #fff;

      .mat-drawer-inner-container {
        display: flex;
        flex-direction: column;
      }

      .side-menu--list {
        h3 {
          padding: $padding;
          text-transform: uppercase;
        }

        h2.list--header {
          margin-top: 16px;
          padding-left: $padding;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }

        .side-menu--item {
          box-sizing: border-box;
          color: #fff;

          &.active {
            background-color: mat-color($palette, soft-blue, 0.6);
            border-left: 3px solid mat-color($primary, 900);

            .mat-icon {
              margin-right: 17px;
            }
          }

          .mat-icon {
            margin-right: 20px;
          }
        }
      }

      .posters-container,
      .logout-btn {
        margin: 10px;
      }

      .logout-btn {
        margin-top: auto;
        color: #fff;
        height: 60px;
        background-color: mat-color($palette, marine-blue);
      }
    }
  }
}
