@import '~@angular/material/theming';

@mixin ssa-page-header-component-theme($theme, $palette) {
  .banner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 960px;
  }

  .banner--darker {
    filter: brightness(0.75);
  }

  .page-header {
    position: relative;
    color: map_get($palette, white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 25vh;
    width: 100%;
    padding: 30px;
    line-height: 35px;

    .app-title {
      white-space: pre-wrap;
      font-size: 32px;
      color: map_get($palette, app-title);
      text-align: center;
    }

    .app-subtitle {
      font-size: 20px;

      h2 {
        color: #fff !important;
      }
    }

    [slot='logo'] {
      margin-bottom: 16px;
    }
  }

  .page-header-brighter {
    position: relative;
    color: map_get($palette, white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 17vh;
    width: 100%;
    padding: 30px;
    line-height: 35px;

    .banner {
      background-size: contain;
      width: 95%;
      height: 86%;
      top: 5%;
      left: 2%;
      min-width: 0px;
    }

    .app-title {
      white-space: pre-wrap;
      font-size: 32px;
      color: #005022;
      position: relative;
      top: 60px;
      transform: translateY(110%);

      @media (min-width: 700px) {
        transform: translateY(80%);
        margin-top: 70px;
      }

      @media (max-width: 700px) {
        top: 60px;
      }

      @media (max-width: 350px) {
        top: 15px;
      }
    }

    .app-subtitle {
      font-size: 20px;
      color: #005022;
      position: relative;
      top: 80px;

      @media (max-width: 700px) {
        top: 110px;
      }

      h2 {
        color: #fff !important;
      }
    }
  }
}
