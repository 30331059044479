@import '~@angular/material/theming';

@mixin paf-default-overrides($theme, $palette) {
  $greyscale: map_get($theme, greyscale);

  .mat-drawer-container {
    color: mat-color($palette, text);
    background-color: mat-color($palette, background);

    .mat-drawer {
      min-width: 270px;
    }

    .mat-sidenav-content {
      overflow: auto;
      display: flex;
      height: 100%;
      flex-direction: column;
    }
  }

  .mat-menu-panel {
    max-width: 350px !important;
  }

  select {
    border: 1px solid silver;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
    background-repeat: no-repeat;
    background-position: 97% 42%;
  }

  .mat-bottom-sheet-container {
    padding: 0 !important;
  }

  button .mat-primary[disabled] {
    background-color: mat-color($palette, primary) !important;
    opacity: 0.6;
    color: #fff !important;
  }

  .mat-checkbox-background {
    background-color: #fff;
  }

  .mat-snack-bar-container {
    &.error {
      background-color: map_get($palette, negative-red);
      .mat-button {
        color: #fff;
      }
    }

    &.warning {
      background-color: map_get($palette, warning-yellow);
      .mat-button {
        color: mat-color($greyscale, 60);
      }
    }

    &.success {
      background-color: map_get($palette, positive-green);
      .mat-button {
        color: mat-color($greyscale, 87);
      }
    }

    &.default {
      background-color: mat-color($greyscale, 100);
      .mat-button {
        color: #fff;
      }
    }
  }

  .mat-select-panel {
    margin-top: 40px;
  }
}
