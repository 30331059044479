@import '~@angular/material/theming';

@mixin ssa-assessor-overview-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .assessor-overview {
    .mat-header-cell {
      color: rgba(0, 0, 0, .7) !important;
      font-weight: bold !important;
      font-size: 14px !important;
    }
  }

  .assessors-page__table-row {
    .mat-cell {
      color: rgba(0, 0, 0, .7) !important;
      font-weight: normal !important;
      font-size: 13px !important;
    }
  }
}
