@import '~@angular/material/theming';

@mixin ssa-expansion-panel($palette) {

  .mat-expansion-panel-header {
    &:hover {
      background-color: mat-color($palette, hover) !important;
    }
  }
  .mat-expansion-panel-header-title {
    align-items: center;
    color: mat-color($palette, text) !important;
    flex-grow: 0;
  }

  .mat-expansion-panel-header-description {
    color: mat-color($palette, text) !important;
    flex-grow: 0;
    margin-left: auto;
  }

  .mat-accordion {
    @include media-breakpoint-down(sm) {
      display: block;
      width: 100vw;
      margin-left: -40px;
    }
  }

  .mat-expansion-indicator {
    &:after {
      color: mat-color($palette, text) !important;
    }
  }
}
