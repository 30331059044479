@import '~@angular/material/theming';

@mixin ssa-update-password-page-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .update-password-page {
    height: 100%;
    overflow-y: auto;

    .login-container {
      width: 280px;
      margin: 40px auto 0 auto;

      .update-password-form {
        display: flex;
        flex-direction: column;

        .form-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #fff;
          margin-bottom: 10px;
          border-radius: $border-radius;
          padding: 20px;

          input {
            border: none;
            flex: 1;
            margin-left: 10px;
            color: mat-color($palette, primary);
            font-size: 16px;

            &::placeholder {
              color: mat-color($primary, 200);
              font-size: 16px;
            }
          }

          .mat-icon {
            width: 10px;
            height: 10px;
            color: mat-color($palette, primary);
            font-size: 10px;
          }
        }

        .form-error {
          color: mat-color($palette, negative-red);
          font-weight: 700;
          font-size: 12px;
          margin-bottom: 10px;
        }

        .mat-checkbox {
          padding-left: 20px;
          color: #fff;
          font-size: 12px;

          a {
            color: #fff;
          }
        }

        .icon-btn {
          margin-top: 54px;
        }
      }
    }
  }
}
