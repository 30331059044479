@import 'variables';
@import 'mixins';
@import '~@angular/material/theming';

.form-group {
  width: 100%;
  @include flexbox();
  @include flex-direction(column);
  margin-bottom: 20px;

  .form-label {
    color: mat-color($ssa-palette, text);
    width: 100%;
    display: block;
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px;

    &.required:after {
      content: "*";
      color: mat-color($ssa-palette, negative-red);
      font-size: 16px;
      padding-left: 3px;
    }
  }

  select {
    &.form-input {
      &.ng-pristine {
        color: mat-color($ssa-palette, deep-sea-blue, 0.4);
      }

      option {
        color: mat-color($ssa-palette, text);

        &:active, &:hover {
          background-color: mat-color($ssa-primary, 700, 0.4);
        }

        &:first-child {
          color: mat-color($ssa-palette, deep-sea-blue, 0.4);
        }
      }
    }
  }

  .form-input {
    border: 2px solid mat-color($ssa-palette, deep-sea-blue, 0.4);
    padding: 10px;
    font-family: "Interstate", sans-serif;
    font-size: 16px;
    font-weight: 200;
    border-radius: 2px;
    background-color: mat-color($ssa-palette, white);
    color: mat-color($ssa-palette, text);

    &::placeholder {
      color: mat-color($ssa-palette, deep-sea-blue, 0.4);
    }

    &.ng-invalid.ng-dirty {
      border: 2px solid mat-color($ssa-palette, negative-red);
    }

    &:focus {
      border: 2px solid mat-color($ssa-palette, positive-green);
    }
  }

  .form-error {
    font-size: 14px;
    padding-top: 5px;
    color: mat-color($ssa-palette, negative-red);
  }

  ol.form-options {
    list-style: none;
    counter-reset: listStyle;
    padding-inline-start: 0;
    padding-left: 0;

    &.horizontal {
      display: flex;
      justify-content: space-between;

      li {
        flex: 1;
        margin-right: 15px;
        float: right;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    li {
      min-height: 60px;
      background-color: #fff;
      counter-increment: listStyle;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 15px;

      &::before {
        content: counter(listStyle, upper-alpha);
        color: mat-color($ssa-palette, soft-blue);
        border: 1px solid mat-color($ssa-palette, border);
        padding: 7px 10px;
        background-color: mat-color($ssa-palette, background);
        margin-right: 15px;
        font-size: 12px;
      }

      &.selected {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);

        &::before {
          background-color: mat-color($ssa-palette, primary);
          color: #fff;
        }
      }

      label {
        flex: 1;
        color: mat-color($ssa-palette, text);
        font-family: "Interstate Light", sans-serif;
        padding: 19px 15px 20px 50px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: -50px;

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.form-button {
  width: 100%;
  height: 60px;
  text-align: center;
  font-family: "Interstate", sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.error-tooltip {
  border: 1px solid mat-color($ssa-palette, negative-red);
  color: mat-color($ssa-palette, negative-red);
  background: #fff;
  border-radius: $border-radius;
  position: relative;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;

  &:after, &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 7px;
    margin-left: -7px;
  }

  &:before {
    border-color: rgba(237, 35, 67, 0);
    border-top-color: mat-color($ssa-palette, negative-red);
    border-width: 9px;
    margin-left: -9px;
  }
}


