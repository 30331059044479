@import '~@angular/material/theming';

@mixin ssa-reset-password-page-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .reset-password-page {
    height: 100%;

    .reset-container {
      width: 280px;
      margin: 75px auto 0 auto;

      .login-form {
        display: flex;
        flex-direction: column;

        .form-group {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #fff;
          margin-bottom: 10px;
          border-radius: $border-radius;
          padding: 20px;

          input {
            border: none;
            flex: 1;
            margin-left: 10px;
            color: mat-color($palette, primary);
            font-size: 16px;

            &::placeholder {
              color: mat-color($primary, 200);
              font-size: 16px;
            }
          }

          .mat-icon {
            width: 10px;
            height: 10px;
            color: mat-color($palette, primary);
            font-size: 10px;
          }
        }

        .icon-btn {
          margin-top: 54px;
        }
      }

      .cancel-link {
        color: mat-color($palette, link);
        text-decoration: none;
        margin-top: 16px;
        display: block;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
