@import '~@angular/material/theming';

* {
  box-sizing: border-box;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  &:active,
  :focus {
    outline: none !important;
  }
}

label {
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: Interstate, "Helvetica Neue", sans-serif;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  overflow: auto;
}

body > div {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

@mixin paf-styles-reset($theme, $palette) {
  $primary: map_get($theme, primary);
  $accent: map_get($theme, accent);
  $foreground: map_get($theme, foreground);

  a {
    color: mat-color($primary);
    cursor: pointer;

    &:hover {
      color: map_get($palette, primary);
    }
  }

  code {
    color: mat-color($primary);
    background-color: mat-color($mat-grey, 300);
  }

  body {
    color: map_get($palette, text);
  }
}
