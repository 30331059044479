@import '~@angular/material/theming';

@mixin ssa-onboarding-page-theme($theme, $palette) {
  .onboarding-info-page {
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    color: mat-color($palette, text);

    .info-content {
      padding: 50px;
    }

    h3.subtitle {
      font-size: 16px;
      color: mat-color($palette, text);
    }

    .mat-raised-button {
      height: 60px;
      margin-top: 32px;
    }
  }
}
