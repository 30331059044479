@import '~@angular/material/theming';

@mixin ssa-administration-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .view-administration-dialog {
    .mat-tab-header {
      margin-bottom: 1rem;
    }

    .mat-tab-body-content {
      overflow-x: hidden;
    }
  }

  .angular-editor-textarea {
    min-height: 10rem !important;
  }

  .view-administration-dialog__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: space-evenly;

      .mat-form-field {
        width: 45%;
      }
    }

    .mat-input-element {
      color: map_get($palette, text) !important;
    }
  }

  .email-container {
    @include media-breakpoint-up(sm) {
      width: 93% !important;
    }
  }
}
