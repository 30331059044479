@import '~@angular/material/theming';

@mixin ssa-password-strength-validator-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .password-validator {
    display: flex;
    flex-direction: column;

    .validation {
      display: flex;
      align-items: flex-start;
      color: mat-color($palette, text);
      padding: 3px 0;

      .mat-icon {
        margin-right: 5px;

        &.success {
          color: mat-color($secondary, 600);
        }
      }
    }
  }
}
