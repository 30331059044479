.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  min-height: 158px;

  .card-header {
    width: 100%;
    .ng-fa-icon {
      margin-right: $spacer;
    }
  }
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .card-#{$abbrev}#{$size} {
      @extend .card;
      #{$prop}: $length !important;
    }
  }
}
