﻿@import '~@angular/material/theming';
@import "../../../../theme/mixins/expansion-panel";

@mixin ssa-question-list-pager-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greyscale: map_get($theme, greyscale);

  .question-list-pager__question-page,
  .question-list-pager__question-list-item {
    margin-bottom: 1rem;
  }

  .question-list-pager__question-list-item {
    box-shadow: inset 0 -1px 0 0 rgba(111, 164, 215, .2);
    margin-bottom: 2rem;

    &:last-of-type {
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  .question-list-pager__question-page-category {
    display: flex;
    align-items: center;
    width: 100vw;
    background-color: white;
    margin-left: -40px;
    padding: 40px;
    height: 1.5rem;
    position: absolute;
    top: 0;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);

    h2, p {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .question-list-pager__question-page-category--title {
    color: mat-color($palette, text) !important;
  }

  .question-list-pager__question-page-category--overview-button {
    margin-left: auto !important;
    margin-right: 1rem !important;
    opacity: 0;
    cursor: auto !important;
  }

  .question-list-pager__question-page-category--overview-button,
  .question-list-pager__question-page-category--count,
  .question-list-pager__question-page-category--category-button {
    color: mat-color($palette, soft-blue);
  }

  .question-list-pager__question-page-category--count {
    background-color: mat-color($palette, background);
    padding: 5px 10px;
    white-space: nowrap;
  }

  .question-list-pager__question-page-content {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .question-list-pager__question-page-overview {
    @include ssa-expansion-panel($palette);
  }

  .question_list-pager__error-container,
  .question_list-pager__category-container {
    height: 20px;
    margin-bottom: 2rem;
  }

  .question-list-pager__category-container,
  .question-list-pager__submit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .question-list-pager__submit-container {
    margin-bottom: 2rem;
  }

  .question-list-pager__button {
    font-size: 14px !important;
    max-width: 280px;
    white-space: nowrap !important;
  }

  .question-list-pager__success-icon {
    color: #3fe02b;
    font-size: 20px;
    vertical-align: middle;
  }

  .assessment-subtitle--optional {
    display: none;
  }

  .question-list-pager__question-page-expansion-panel-description {
    width: 5rem;
  }
}
