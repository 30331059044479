﻿@import "~@angular/material/theming";

@mixin ssa-question-list-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greyscale: map_get($theme, greyscale);
}

.error {
  color: red;
}
