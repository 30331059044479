@import '~@angular/material/theming';

@mixin ssa-assessment-overview-page-theme($theme, $palette) {
  .assessment-overview {
    width: 90%;
    margin: 4rem auto;

    table {
      width: 100%;
    }

    .mat-header-cell {
      color: rgba(0, 0, 0, .7) !important;
      font-weight: bold !important;
      font-size: 14px !important;
    }
  }

  .assessment-overview__table-row {
    .mat-cell {
      color: rgba(0, 0, 0, .7) !important;
      font-weight: normal !important;
      font-size: 13px !important;
    }
  }
}
