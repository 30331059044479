@import '~@angular/material/theming';

@mixin ssa-edit-translation-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .add-users-dialog {

    .header {
      font-weight: 700;
      font-size: 22px;
      color: mat-color($palette, marine-blue);
      margin-bottom: 20px;
    }

    .button-bar {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;

      .mat-button {
        flex: 1;
      }
    }

  }

  .edit-translation__input-area {
    padding: 0 !important;
    margin: 0 !important;
  }
}
