@import '~@angular/material/theming';

agm-map {
  height: 350px !important;
  width: 500px;

  @media (max-width:600px) {
    width: 350px;
  }

  @media (max-width:900px) {
    width: 420px;
  }

  @media (max-width:410px) {
    width: 270px;
    height: 270px !important;
  }
}

.active {
    background: #2972ffa1 !important;
  }

@mixin ssa-registration-page-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $warn: map_get($theme, warn);
  $black: map_get($theme, greyscale);

  .registration-info-page {
    height: 100vh;
    overflow-y: auto;
    color: mat-color($palette, text);

    .info-content {
      padding: 50px;
    }

    h3.subtitle {
      font-size: 24px;
      color: #fff;
    }

    .mat-raised-button {
      height: 60px;
      margin-top: 20px;
    }
  }

  .registration-page {
    height: 100%;
    overflow-y: auto;

    .page-header {
      .app-subtitle {
        font-size: 28px;
      }
    }

    .registration-container {
      margin: 5rem auto 0;
      width: 100%;
      max-width: 320px;
      float: left;
      margin-left: 300px;

      @media (max-width:1150px) {
        margin-left: 50px;
      }

      .registration-form {
        display: block;

        .form-group {
          @media (max-width:360px) {
            width: 75%;
          }
        }

        .form-part {
          margin-bottom: 50px;

          h1.registration-header {
            font-size: 22px;
            color: mat-color($palette, text);
            font-weight: bold;
          }

          .maps {
            display: inline-flex;

            @media (max-width:600px) {
              display: inline-block;
              margin-bottom: 10px;
            }

            .mapsList {
              margin-right: 25px;
              max-height: 340px;
              overflow: auto;
              width: max-content;

              @media (max-width:600px) {
                margin-left: 0px;
                margin-bottom: 15px;
              }

              @media (max-width:360px) {
                margin-left: -15px;
                margin-bottom: 15px;
                width: 300px;
              }

              .mat-nav-list .mat-list-item {
                font-size: small;
                margin-right: 5px;
              }

              .mat-list-item:hover {
                background: #eaeaf1;
              }
            }
          }

          .phonenumberInput {
            padding: 10px;
            width: 153%;
            padding-left: 50px;
            border: 2px solid var(--theme-primary-deep-sea-blue);

            @media (max-width:360px) {
              width: 115%;
            }
          }

          .form-label {
            color: mat-color($palette, text);

            .mat-icon {
              background-color: #fff;
              border-radius: 50%;
              border: none;
            }
          }

          .form-error {
            background-color: mat-color($warn, 100);
            padding: 10px;
            display: flex;
            align-items: center;
            border: 1px solid mat-color($warn, 500);

            .mat-icon {
              margin-right: 5px;
            }
          }

          .country-code-wrapper {
            display: flex;

            .country-code-select {
              width: 165px;
              margin-right: 8px;
            }

            input.form-input {
              width: 100%;
            }
          }
        }

        .icon-btn {
          margin-top: 8px;
        }

        .password-input-wrapper {
          position: relative;

          .form-input {
            width: 100%;
          }

          .mat-icon-button {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }

      .cancel-link {
        color: mat-color($palette, text);
        text-decoration: underline;
        margin: 20px 0;
        display: block;
        text-align: center;
        font-size: 12px;
      }

      .mat-checkbox {
        color: mat-color($palette, text);
        font-size: 14px;

        a {
          color: mat-color($palette, text);
        }

        .mat-checkbox-frame {
          z-index: 1;
        }
      }
    }

    .selected-facility {
        outline: none !important;
        border: 2px solid;
        border-image-slice: 1 !important;
        border-width: 3px;
        border-image-source: linear-gradient(to right, #0d6efd, #3d4095);
        animation: animate 2.5s linear infinite;

    @keyframes animate {
      100%{
        filter: hue-rotate(-360deg);
      }
    }
  }
 }
}
