﻿@import '~@angular/material/theming';

@mixin ssa-questionnaire-page-theme($theme, $palette) {
  .onboarding-info-page {
    overflow-y: auto;
    color: mat-color($palette, text);

    .info-content {
      padding: 50px;
    }

    h3.subtitle {
      font-size: 16px;
      color: mat-color($palette, text);
    }

    .mat-raised-button {
      height: 60px;
      margin-top: 32px;
    }
  }

  .assessment-subtitle {
    font-size: 24px !important;
    width: 100% !important;
    margin-bottom: 2rem !important;
    text-align: center;
  }

  .navigation-bar {
    height: 60px;
    line-height: 60px;
    min-height: 60px;
    margin: 10px 0 30px 0;
    display: flex;
    justify-content: center;

    .submit-button {
      width: 180px;
      height: 60px;
      line-height: 20px;
      min-height: 60px;
      white-space: pre-wrap !important;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .export-button-bar {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;

    .export-button {
      background-color: mat-color($palette, pale-grey);
      border: 1px solid mat-color($palette, light-blue-grey);
      border-radius: $border-radius;
      color: mat-color($palette, text);
      font-family: "Interstate Light", sans-serif;
      font-size: 12px;
      width: 180px;
      white-space: pre-wrap !important;
      line-height: 17px;
      padding: 10px;

      &:first-child {
        margin-right: 10px;
      }

      &:nth-child(2) {
        margin-right: 10px;
      }

      &:nth-child(3) {
        margin-right: 10px;
      }

      .mat-icon {
        color: mat-color($palette, light-blue-grey);
        margin-right: 10px;
        font-size: 8px;
        width: 8px;
        height: 11px;
      }
    }
  }
}
