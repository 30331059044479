:root {
  --theme-primary-50: #e0eaf1;
  --theme-primary-100: #b3ccdd;
  --theme-primary-200: #80aac7;
  --theme-primary-300: #4d87b0;
  --theme-primary-400: #266e9f;
  --theme-primary-500: #00548e;
  --theme-primary-600: #004d86;
  --theme-primary-700: #00437b;
  --theme-primary-800: #003a71;
  --theme-primary-900: #00295f;
  --theme-primary-A100: #90b6ff;
  --theme-primary-A200: #5d94ff;
  --theme-primary-A400: #2a73ff;
  --theme-primary-A700: #1062ff;
  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #ffffff;
  --theme-primary-contrast-400: #ffffff;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #ffffff;
  --theme-primary-contrast-A700: #ffffff;

  --theme-primary-deep-sea-blue: #00548e;
  --theme-primary-marine-blue: #013759;
  --theme-primary-soft-blue: #6fa4d7;
  --theme-primary-border: #d6e5f3;
  --theme-primary-hover: #d6e5f3;
  --theme-primary-background: #f4f6f8;
  --theme-primary-text: #2a4252;
  --theme-app-title: #fff;
}

$ssa-primary: (
  50 : var(--theme-primary-50),
  100 : var(--theme-primary-100),
  200 : var(--theme-primary-200),
  300 : var(--theme-primary-300),
  400 : var(--theme-primary-400),
  500 : var(--theme-primary-500),
  600 : var(--theme-primary-600),
  700 : var(--theme-primary-700),
  800 : var(--theme-primary-800),
  900 : var(--theme-primary-900),
  A100 : var(--theme-primary-A100),
  A200 : var(--theme-primary-A200),
  A400 : var(--theme-primary-A400),
  A700 : var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  )
);

$ssa-secondary: (
  50 : #e4f4eb,
  100 : #bbe3cc,
  200 : #8ed1ab,
  300 : #61bf89,
  400 : #3fb16f,
  500 : #1da356,
  600 : #1a9b4f,
  700 : #159145,
  800 : #11883c,
  900 : #0a772b,
  A100 : #a7ffbe,
  A200 : #74ff98,
  A400 : #41ff72,
  A700 : #28ff5f,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$ssa-warning: (
  50 : #fae3e4,
  100 : #f3babc,
  200 : #eb8c90,
  300 : #e35e63,
  400 : #dd3c41,
  500 : #d71920,
  600 : #d3161c,
  700 : #cd1218,
  800 : #c70e13,
  900 : #be080b,
  A100 : #ffe8e8,
  A200 : #ffb5b6,
  A400 : #ff8283,
  A700 : #ff696a,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$paf-black: (
  5: #f4f6f8,
  22: #c9cfd3,
  38: #a2acb3,
  60: #6c7d88,
  87: #2a4252,
  100: #0a2638
);


$ssa-palette: (
  primary: map_get($ssa-primary, 500),
  accent: map_get($ssa-secondary, 500),
  app-title: var(--theme-app-title),
  white: #ffffff,
  accent-green: #76b354,
  neutral-blue: #8ac7e6,
  positive-green: #70CB40,
  warning-yellow: #F1CB2B,
  warning-orange: #f1a82b,
  negative-red: #ED2343,
  text: var(--theme-primary-text),
  pale-grey: #f0f5fb,
  deep-sea-blue: var(--theme-primary-deep-sea-blue),
  marine-blue: var(--theme-primary-marine-blue),
  soft-blue: var(--theme-primary-soft-blue),
  link: map_get($ssa-secondary, 300),
  border: var(--theme-primary-border),
  hover: var(--theme-primary-hover),
  light-blue-grey: #b1cbe5,
  background: var(--theme-primary-background)
);
$enable-grid-classes: true;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1263px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

$spacer: 1rem !default; //16px
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25), // 4px
  2: ($spacer * .5),  // 8px
  3: $spacer,         // 16px
  4: ($spacer * 1.5), // 24px
  5: ($spacer * 3)    // 48px
), $spacers);

$mat-elevation-color: #000;
$mat-elevation-opacity: 0.1;
$border-radius: 5px;
$padding: 16px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$header-height: 64px;
$header-height-sm: 56px;
$border-radius: 4px;
