@import '~@angular/material/theming';

@mixin ssa-popover-component-theme($theme, $palette) {
  .ssa-popover {
    .mat-menu-item {
      cursor: auto;
      height: auto;
      white-space: normal;
      line-height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem 1rem;

      &.cdk-program-focused:not([disabled]),
      &:hover:not([disabled]) {
        background: inherit;
      }
    }
    .mat-menu-content {
      padding: 0;
    }
  }

  .trigger {
    display: inline-block;
  }
}
