@import '~@angular/material/theming';

@mixin ssa-side-menu-additional-resources-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .side-menu-additional-resources__title {
    margin-top: 16px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .side-menu-additional-resources__item {
    display: flex;
    text-decoration: none;
    color: #fff;
    height: 48px;

    .mat-icon {
      margin-right: 20px;
      margin-left: 6px;
    }

    .menu-label {
      font-size: 16px;
    }

    &:hover {
      color: #fff;
    }
  }
}
