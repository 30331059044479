@import '~@angular/material/theming';

@mixin ssa-confirmation-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  h2 {
    color: mat-color($secondary, 600);
  }
}
