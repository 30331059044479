@import '~@angular/material/theming';

@mixin ssa-assessors-page-theme($theme, $palette) {
  .assessors-page {
    @extend .container;

    table {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .assessors-page__top-row {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
  }

  .top-row__download-button {
    height: 40px;
    align-self: flex-end;
    margin-left: auto;
    cursor: pointer;
  }

  .top-row__add-facility-button {
    height: 40px;
    line-height: 10px !important;
    align-self: center;
    margin-left: 1rem;
  }

  .top-row__search-bar {
    flex-grow: 1;

    mat-form-field {
      width: 90%;
    }
  }

  .assessors-page__table-row {
    cursor: pointer;

    &:hover {
      background-color: mat-color($palette, hover);
    }
  }
}
