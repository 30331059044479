@import '~@angular/material/theming';

@mixin ssa-facilities-page-theme($theme, $palette) {
  .facilities-page {
    @extend .container;

    table {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .facilities-page__top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .top-row__search-bar {
      flex-grow: 1;

      mat-form-field {
        max-width: 90%;
      }
    }

    .top-row__download-button {
      height: 40px;
      margin-right: 2rem;
      align-self: flex-end;
      cursor: pointer;
    }

    .top-row__add-facility-button {
      height: 40px;
      line-height: 10px !important;
      margin: 0 4px;
    }
  }

  .facilities-page__table-row {
    cursor: pointer;

    &:hover {
      background-color: mat-color($palette, hover);
    }
  }
}
