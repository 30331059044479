@import '~@angular/material/theming';

@mixin ssa-question-viewer-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greyscale: map_get($theme, greyscale);

  .form-label--parent {
    margin-bottom: 0 !important;
  }

  .form-label--child {
    font-size: 16px !important;
  }

  .question-viewer__title-container--parent {
    font-weight: bold;
  }

  .question-viewer__title-container {
    display: flex;
    align-items: center;
  }

  .question-viewer__title-info-button {
    margin-left: auto;
  }

  .question-viewer__note-title {
    margin: 0 !important;
  }

  .question-viewer__note-container {
    border: none !important;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    font-size: 14px !important;
  }

  .option-image-container {
    display: flex;
    flex-direction: column;

    .option-image {
      max-width: 100%;
    }
  }
}
