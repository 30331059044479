@import '~@angular/material/theming';

@mixin paf-global-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .unauthorized-page,
  .page {
    left: 0;
    right: 0;
    top: 56px;
    bottom: 0;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    contain: layout size style;
    overflow-x: hidden;
    overflow-y: auto;

    .page-content {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 40px;
      background-color: mat-color($palette, background);
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      contain: size style;
      flex: 1 1 0;
      box-sizing: border-box;
    }

    @include media-breakpoint-up(sm) {
      top: 64px;
    }
  }

  .unauthorized-page {
    top: 0;
  }

  h1 {
    color: mat-color($primary, 900);
    font-size: 20px;
    margin: 0;
  }

  h2 {
    color: map_get($palette, text);
    font-size: 16px;
    display: inline-block;
    width: auto;
    margin: 0;
    font-weight: normal;
  }

  h3 {
    color: map_get($palette, text);
    font-size: 14px;
    display: inline-block;
    width: auto;
    margin: 0;
    font-weight: normal;
  }

  .dialog {
    max-width: 80vw;
  }

  .custom-form-field {
    &:not(:first-child) {
      margin-top: 16px;
    }

    width: 100%;

    &.mat-focused .mat-form-field-flex {
      border: 2px solid mat-color($palette, focus-border) !important;
    }

    .mat-icon {
      font-size: 100%;
      color: mat-color($palette, primary);

      &.lock {
        opacity: 0.6;
      }
    }

    &.mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: #fff;
        border-radius: $border-radius;
        border: 2px solid mat-color($palette, deep-sea-blue, 0.4);

        .mat-select-value, .mat-form-field-prefix {
          color: mat-color($palette, primary);
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          align-self: center;
        }

        .mat-form-field-prefix {
          margin-right: 8px;
        }
      }

      .mat-form-field-underline {
        display: none;
      }

      &.mat-form-field-invalid {
        .mat-form-field-flex {
          border: 2px solid mat-color($palette, negative-red);
        }
      }
    }
  }

  .mat-option.mat-active {
    background-color: mat-color($palette, hover) !important;
  }

  .mat-option{
    &:hover {
      background-color: mat-color($palette, hover) !important;
    }
  }

  .country-code-select {
    /* Country images copied from https://countrycode.org */
    .flag {
      padding: 0 !important;
      margin: 0 5px 0 0;
      width: 16px;
      height: 11px;
      background: url(/assets/images/flagssprite.png) no-repeat;

      &.Other {
        background: none;
      }

      &.Burundi {
        background-position: 0 -253px
      }

      &.Congo-Brazzaville {
        background-position: 0 -440px
      }

      &.Democratic-Republic-of-the-Congo {
        background-position: 0 -451px
      }

      &.Ivory-Coast {
        background-position: 0 -462px
      }

      &.Algeria {
        background-position: -16px -77px
      }

      &.Egypt {
        background-position: -16px -110px
      }

      &.Ethiopia {
        background-position: -16px -165px
      }

      &.Ghana {
        background-position: -16px -319px
      }

      &.Haiti {
        background-position: -16px -506px
      }

      &.Indonesia {
        background-position: -16px -528px
      }

      &.India {
        background-position: -16px -561px
      }

      &.Jamaica {
        background-position: -32px -44px
      }

      &.Kenya {
        background-position: -32px -77px
      }

      &.Cambodia {
        background-position: -32px -99px
      }

      &.Laos {
        background-position: -32px -198px
      }

      &.Saint-Lucia {
        background-position: -32px -220px
      }

      &.Liberia {
        background-position: -32px -242px
      }

      &.Sri-Lanka {
        background-position: -32px -253px
      }

      &.Myanmar {
        background-position: -32px -407px
      }

      &.Malawi {
        background-position: -32px -517px
      }

      &.Mozambique {
        background-position: -32px -550px
      }

      &.Namibia {
        background-position: -32px -561px
      }

      &.Nigeria {
        background-position: -48px -22px
      }

      &.Netherlands {
        background-position: -48px -44px
      }

      &.Peru {
        background-position: -48px -132px
      }

      &.Papua-New-Guinea {
        background-position: -48px -154px
      }

      &.Russia {
        background-position: -48px -319px
      }

      &.Rwanda {
        background-position: -48px -330px
      }

      &.Solomon-Islands {
        background-position: -48px -352px
      }

      &.Sierra-Leone {
        background-position: -48px -462px
      }

      &.Suriname {
        background-position: -48px -506px
      }

      &.El-Salvador {
        background-position: -48px -528px
      }

      &.Tunisia {
        background-position: -64px -77px
      }

      &.Tuvalu {
        background-position: -64px -121px
      }

      &.Tanzania {
        background-position: -64px -143px
      }

      &.Uganda {
        background-position: -64px -165px
      }

      &.Vietnam {
        background-position: -64px -275px
      }

      &.Yemen {
        background-position: -64px -330px
      }
    }
  }

  .invalid-message {
    color: mat-color($palette, negative-red);
  }
}
