﻿@import '~@angular/material/theming';

@mixin ssa-home-page-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .home-page {
    height: 100%;
    overflow-y: auto;

    .home-container {
      max-width: 280px;
      margin: 55px auto 0 auto;
      padding-bottom: 20px;
    }

    .icon-btn {
      margin-top: 25px;
      line-height: 20px !important;
      white-space: pre-wrap !important;
    }
  }
}
