@import '~@angular/material/theming';

@mixin ssa-translations-page-theme($theme, $palette) {
  .translations-page {
    @extend .container;

    .mat-tree {
      padding: $padding;
    }

    .mat-nested-tree-node {
      li {
        &::marker {
          color: transparent;
        }
      }
    }

    .translations-tree-invisible {
      display: none;
    }

    .translations-tree ul,
    .translations-tree li {
      margin-top: 0;
      margin-bottom: 0;
      list-style-type: none;

      .mat-tree-node {
        width: 100%;
        cursor: pointer;

        &:hover {
          background-color: mat-color($palette, hover);
        }
      }
    }
  }

  .mat-tooltip {
    white-space: pre-line;
  }

  .toolbar {
    display: flex;
    align-items: baseline;

    .mat-form-field {
      margin: 0 1rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
