@import '~@angular/material/theming';

@mixin ssa-facility-on-boarding-page-theme($theme, $palette) {
  .facility-on-boarding-page {
    @extend .container;
  }

  .facility-on-boarding-page__button-bar {
    display: flex;
    justify-content: flex-end;
  }
}
