@font-face {
  font-family: "Interstate Black Italic";
  font-style: italic;
  font-weight: 500;
  src: url("Interstate Black Italic.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Black";
  font-style: normal;
  font-weight: 700;
  src: url("Interstate Black.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Italic";
  font-style: italic;
  font-weight: 500;
  src: url("Interstate Italic.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Light Italic";
  font-style: italic;
  font-weight: 300;
  src: url("Interstate Light Italic.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Light";
  font-style: normal;
  font-weight: 300;
  src: url("Interstate Light.otf") format("opentype");
}

@font-face {
  font-family: "Interstate";
  font-style: normal;
  font-weight: 500;
  src: url("Interstate Regular.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Condensed";
  font-style: normal;
  font-weight: 700;
  font-stretch: condensed;
  src: url("Interstate Black Condensed.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Regular Compressed";
  src: url('Interstate Regular Compressed.otf');
}

