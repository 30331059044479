@import '~@angular/material/theming';

@mixin ssa-login-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .login-page {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 30px;

    .safecare-logo {
      width: 100%;
      max-width: 280px;
      display: block;
      margin: 0 auto;
    }

    .safecare-logo-small {
      width: 100%;
      max-width: 140px;
      display: block;
      margin: 0 auto;
    }

    .login-container {
      margin: 90px auto 0 auto;
      max-width: 280px;

      .mat-card {
        padding: map_get($spacers, 4);
      }

      .icon-btn {
        margin-top: 24px;
      }

      .sign-up-container {
        max-width: 375px;
        height: 60px;
        background-color: mat-color($black, 5);
        border: 2px solid mat-color($black, 38);
        border-radius: $border-radius;
        padding: 16px;
        display: flex;
        align-items: center;
        margin-top: 16px;

        a {
          padding-left: 4px;
          font-weight: bold;
          text-decoration: none;
        }
      }

      .forget-password-link {
        color: mat-color($palette, text);
        text-decoration: none;
        display: block;
        font-size: 14px;
        text-align: right;
      }

      .password-field {
        margin-top: 0 !important;
      }
    }
  }
}
