@import '~@angular/material/theming';

@mixin ssa-admin-home-page-theme($theme, $palette) {
  .admin-home-page {
    height: 100%;
    overflow-y: auto;

    .home-container {
      max-width: 280px;
      margin: 50px auto 0 auto;
      padding-bottom: 20px;
    }

    .mat-raised-button {
      height: 55px;
      color: #fff;
      margin-top: 25px;
      line-height: 20px !important;
      white-space: pre-wrap !important;
    }
  }
}
