@import '~@angular/material/theming';

@mixin ssa-notification-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .notification-container {
    @include flexbox();
    @include justify-content(space-around);
    color: mat-color($palette, white);
    @include align-items(center);

    .mat-icon {
      margin-right: 10px;
    }

    .message {
      @include flex(1);;
    }
  }
}
