@import '~@angular/material/theming';

@mixin ssa-guidelines-popover-component-theme($theme, $palette) {
  dl.guidelines-popover-list {
    margin: 0;
    width: 100%;
    color: mat-color($palette, text);

    dt {
      font-weight: bold;
    }

    dd {
      margin-inline-start: 0;
      margin-left: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .mat-icon-button.guidelines-popover-btn {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }

  .guidelines-popover-title {
    margin-bottom: 1rem;
    box-shadow: inset 0 -1px 0 0 rgba(111, 164, 215, .2);
  }
}
