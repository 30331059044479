@import '~@angular/material/theming';

@mixin ssa-score-page-theme($theme, $palette) {
  .score-page {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - #{$header-height});
    padding: 25px 20px 30px;

    @media (max-width: 959px) {
      height: calc(100vh - #{$header-height-sm});
    }

    .score-page__score-display {
      text-align: center;
      padding: 0 20px;
    }

    .score-container__score-display-image {
      background-image: url('/assets/images/clouds.svg');
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
    }

    .score-page__score-display-text-container {
      color: mat-color($palette, text);
      line-height: 30px;
      font-weight: bold;

      .score-text {
        font-size: 22px;
        display: block;
      }

      .score-text--green {
        color: mat-color($palette, accent);
      }

      .score-text--orange {
        color: mat-color($palette, warning-orange);
      }

      .score-text--red {
        color: mat-color($palette, negative-red);
      }

      .score-participation {
        font-size: 18px;
      }
    }

    .score-page__score-display-score-container--assessor,
    .score-page__score-display-score-container {
      border-radius: 30px;
      background-color: mat-color($palette, primary);
      margin-top: 2rem;
      color: #fff;
      padding: 2rem;
      min-width: 15rem;
    }

    .nhis-score-page__score-display-score-container--assessor-green,
    .nhis-score-page__score-display-score-container-green {
      border-radius: 30px;
      background-color: mat-color($palette, accent);
      margin-top: 2rem;
      color: #fff;
      padding: 2rem;
      min-width: 15rem;

      .score-label {
        font-size: 18px;
        margin-right: 5px;
        margin-bottom: 1rem;
      }

      .score-number {
        font-weight: 700;
        font-size: 22px;
      }

      .score-divider {
        font-size: 18px;
        margin: 0 5px;
      }
    }

    .nhis-score-page__score-display-score-container--assessor-red,
    .nhis-score-page__score-display-score-container-red {
      border-radius: 30px;
      background-color: mat-color($palette, negative-red);
      margin-top: 2rem;
      color: #fff;
      padding: 2rem;
      min-width: 15rem;

      .score-label {
        font-size: 18px;
        margin-right: 5px;
        margin-bottom: 1rem;
      }

      .score-number {
        font-weight: 700;
        font-size: 22px;
      }

      .score-divider {
        font-size: 18px;
        margin: 0 5px;
      }
    }

    .score-page__score-display-score-container {
      .score-label {
        font-size: 18px;
        margin-right: 5px;
        margin-bottom: 1rem;
      }

      .score-number {
        font-weight: 700;
        font-size: 22px;
      }

      .score-divider {
        font-size: 18px;
        margin: 0 5px;
      }
    }

    .score-page__score-display-score-container--assessor {
      display: flex;
      justify-content: center;

      .score-label {
        display: none;
      }

      .score-number,
      .score-divider {
        font-size: 30px;
      }

      .score-number {
        font-weight: 700;
      }

      .score-divider {
        margin: 0 5px;
      }
    }

    .score-page__score-display-explanation {
      font-weight: 200;
      font-size: 16px;
      color: mat-color($palette, text);
      line-height: 24px;
      margin: 30px 0;
    }

    .icon-btn {
      width: 100%;
    }
  }

  .score-page__assessor-comment {
    margin-bottom: 2rem;
  }
  .score-page__assessor-comment-title {
    margin: 0 !important;
  }

  .score-page__assessor-comment-container {
    border: none !important;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    font-size: 14px !important;
    height: 5rem;
    padding: 10px;
  }
}
