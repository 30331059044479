@import '~@angular/material/theming';

@mixin ssa-add-facility-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $black: map_get($theme, greyscale);

  .add-facility-dialog {
    max-height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .header {
      font-weight: 700;
      font-size: 22px;
      color: mat-color($palette, marine-blue);
      margin-bottom: 20px;
    }


    .form {
      overflow-x: auto;
      padding-right: 20px;
    }

    .button-bar {
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;

      button {
        margin-right: 1rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    @media print {
      @page {
        margin: 0;
      }

      body {
        margin: 1.6cm;
      }

      .button-bar,
      .facility-header {
        display: none;
      }
    }
  }

  .edit-translation__input-area {
    padding: 0 !important;
    margin: 0 !important;
  }
}
